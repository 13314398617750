import React, { useState, createContext, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  format,
  addYears,
  isBefore,
  isEqual,
  addDays,
  parseISO,
  startOfDay,
  endOfDay,
} from "date-fns";
import { formatDistanceToNow } from "date-fns";
import HomePage from "./pages/HomePage";
import EventPage from "./pages/EventPage";
import CreateEvent from "./pages/CreateEventPage";
import ConfettiAnimation from "./components/LottieConfetti";
import { detectTimeZone } from "./utils/timeZone";
import { ThemeProvider, ThemeContext } from "./components/ThemeContext";
import { categories } from "./data/CategoryData";
import { updateSpecialDates } from "./components/DateCalculations";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Terms from "./pages/Terms";
import Contact from "./pages/Contact";

export const TimeZoneContext = createContext();
export const useTimeZone = () => useContext(TimeZoneContext);

const App = () => {
  const [events, setEvents] = useState(
    categories[categories.length - 1].options
  );
  const [timeZone, setTimeZone] = useState(() => {
    return localStorage.getItem("userTimeZone") || detectTimeZone();
  });
  const [isEventDay, setIsEventDay] = useState(false);

  useEffect(() => {
    const updateEvents = () => {
      const now = new Date();
      const updatedEvents = events.map((event) => {
        let eventDate = parseISO(event.date);
        let isThisEventDay = false;

        if (event.title === "Shark Week") {
          // For Shark Week, we add a 7-day buffer
          if (isBefore(addDays(eventDate, 7), now)) {
            eventDate = addYears(eventDate, 1);
          }
        } else {
          // Check if it's the event day
          if (isEqual(startOfDay(eventDate), startOfDay(now))) {
            isThisEventDay = true;
          } else if (isBefore(eventDate, now)) {
            // If the event is in the past (but not today), add a year
            eventDate = addYears(eventDate, 1);
          }
        }
        
        const formattedDate = format(eventDate, "do MMM yyyy");
        const timeToEvent = formatDistanceToNow(eventDate, { addSuffix: true });
        return {
          ...event,
          date: format(eventDate, "yyyy-MM-dd"), // Keep the original format for calculations
          formattedDate, // Add the new formatted date
          timeToEvent, // Add time to event
          isEventDay: isThisEventDay,
        };
      });

      // Apply special date updates
      const finalEvents = updateSpecialDates(updatedEvents, timeZone);
      setEvents(finalEvents);

      // Check if any event is on its event day and not past midnight
      const isAnyEventDay = finalEvents.some((event) => {
        const eventDate = parseISO(event.date);
        return (
          isEqual(startOfDay(eventDate), startOfDay(now)) &&
          isBefore(now, endOfDay(eventDate))
        );
      });
      setIsEventDay(isAnyEventDay);

      // Debug logs
    };

    updateEvents();

    // Set up an interval to update events every minute
    const interval = setInterval(updateEvents, 600000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [timeZone]); // Dependency on timeZone ensures updates when it changes

  const addEvent = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };

  const updateTimeZone = (newTimeZone) => {
    localStorage.setItem("userTimeZone", newTimeZone);
    setTimeZone(newTimeZone);
  };

  return (
    <ThemeProvider>
      <TimeZoneContext.Provider value={{ timeZone, updateTimeZone }}>
        <Router>
          <AppContent
            events={events}
            addEvent={addEvent}
            isEventDay={isEventDay}
          />
        </Router>
      </TimeZoneContext.Provider>
    </ThemeProvider>
  );
};

const AppContent = ({ events, addEvent, isEventDay }) => {
  const { isDarkMode } = useContext(ThemeContext);
  return (
    <div className={isDarkMode ? "dark" : ""}>
      <ConfettiAnimation isPlaying={isEventDay} />
      <Routes>
        <Route path="/" element={<HomePage events={events} />} />
        <Route path="/event/:id" element={<EventPage />} />
        <Route
          path="/event/:categoryIndex/:optionIndex/:extensionCategoryIndex/:extensionIndex/:date/:imageIndex"
          element={<EventPage />}
        />
        <Route path="/create" element={<CreateEvent addEvent={addEvent} />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </div>
  );
};

export default App;
