import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import {
  categories,
  imageOptions,
  extensionCategories,
} from "../data/CategoryData";
import Navbar from "../components/Navbar";
import { ThemeContext } from "../components/ThemeContext";
import { FiX } from "react-icons/fi"; // Make sure to install react-icons
import Footer from '../components/Footer';


const modCategories = categories.slice(0, categories.length - 1);

// CenteredAd component
const CenteredAd = ({ onClose }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
  >
    <motion.div
      initial={{ scale: 0.9 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0.9 }}
      className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full relative"
    >
      <button
        onClick={onClose}
        className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
      >
        <FiX size={24} />
      </button>
      <h3 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
        Advertisement
      </h3>
      <div className="h-64 bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
        <p className="text-gray-600 dark:text-gray-400">Your ad content here</p>
      </div>
    </motion.div>
  </motion.div>
);

const CreateEvent = ({ addEvent }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedExtensionCategory, setSelectedExtensionCategory] =
    useState("");
  const [selectedExtension, setSelectedExtension] = useState("");
  const [date, setDate] = useState("");
  const [hour, setHour] = useState("12");
  const [minute, setMinute] = useState("00");
  const [ampm, setAmpm] = useState("AM");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [showAd, setShowAd] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  const handleCategoryChange = (e) => {
    const category = modCategories.find((c) => c.id === e.target.value);
    setSelectedCategory(e.target.value);
    setSelectedOption("");
    setBackgroundImage(category ? category.defaultImage : "");
    setInvalidFields(invalidFields.filter((field) => field !== "category"));
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    const category = modCategories.find((c) => c.id === selectedCategory);
    const option = category.options.find((o) => o.name === e.target.value);
    setBackgroundImage(
      option && option.image ? option.image : category.defaultImage
    );
    setInvalidFields(invalidFields.filter((field) => field !== "event"));
  };

  const handleExtensionCategoryChange = (e) => {
    setSelectedExtensionCategory(e.target.value);
    setSelectedExtension("");
  };

  const handleExtensionChange = (e) => {
    setSelectedExtension(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setInvalidFields(invalidFields.filter((field) => field !== "date"));
  };

  const handleImageSelect = (image) => {
    setBackgroundImage(image);
    setShowImageSelector(false);
  };

  const validateForm = () => {
    const newInvalidFields = [];
    if (!selectedCategory) newInvalidFields.push("category");
    if (!selectedOption) newInvalidFields.push("event");
    if (!date) newInvalidFields.push("date");
    setInvalidFields(newInvalidFields);
    return newInvalidFields.length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const categoryIndex = modCategories.findIndex(
      (c) => c.id === selectedCategory
    );
    const optionIndex = modCategories[categoryIndex].options.findIndex(
      (o) => o.name === selectedOption
    );
    const extensionCategoryIndex = extensionCategories.findIndex(
      (c) => c.id === selectedExtensionCategory
    );
    const extensionIndex =
      extensionCategoryIndex !== -1
        ? extensionCategories[extensionCategoryIndex].extensions.indexOf(
            selectedExtension
          )
        : -1;
    const imageIndex = imageOptions.indexOf(backgroundImage);

    // Convert 12-hour time to 24-hour time
    let hours = parseInt(hour);
    if (ampm === "PM" && hours !== 12) {
      hours += 12;
    } else if (ampm === "AM" && hours === 12) {
      hours = 0;
    }
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minute}`;

    const newEvent = {
      categoryIndex,
      optionIndex,
      extensionCategoryIndex,
      extensionIndex,
      date: `${date}T${formattedTime}`,
      imageIndex,
      selectedOption,
      selectedExtension,
      backgroundImage,
    };

    addEvent(newEvent);

    const link = `/event/${categoryIndex}/${optionIndex}/${extensionCategoryIndex}/${extensionIndex}/${date}T${formattedTime}/${imageIndex}`;
    setPendingNavigation(link);
    setShowAd(true);
  };

  const handleAdClose = () => {
    setShowAd(false);
    // Scroll to top
    window.scrollTo(0, 0);
    // Use setTimeout to ensure the scroll happens before navigation
    setTimeout(() => {
      if (pendingNavigation) {
        navigate(pendingNavigation);
      }
    }, 100);
  };

  // Use useEffect to scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getInputClassName = (fieldName) => {
    return `w-full p-2 border ${
      invalidFields.includes(fieldName)
        ? "border-red-500 focus:ring-red-500 focus:border-red-500"
        : "border-gray-300 focus:ring-blue-500 focus:border-blue-500"
    } rounded-md dark:bg-gray-600 dark:border-gray-600`;
  };

  return (
    <div className={`min-h-screen flex flex-col ${isDarkMode ? "dark" : ""}`}>
      <div className="flex-grow bg-gray-100 dark:bg-gray-900 flex flex-col">
        <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} />

        <div className="bg-gray-200 dark:bg-gray-800 p-4"></div>

        <div className="flex-grow flex flex-col md:flex-row">
          <div className="hidden md:block w-1/6 bg-gray-200 dark:bg-gray-800"></div>

          <div className="flex-grow bg-gradient-to-br from-blue-100 to-blue-300 dark:from-gray-900 dark:to-black p-4 flex items-center justify-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="w-full max-w-2xl bg-white dark:bg-gray-800 rounded-lg shadow-xl p-8"
            >
              <h2 className="text-3xl font-bold text-blue-900 dark:text-blue-300 mb-6">
                Create New Countdown
              </h2>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Category
                  </label>
                  <select
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    className={getInputClassName("category")}
                  >
                    <option value="">Select a category</option>
                    {modCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                  {invalidFields.includes("category") && (
                    <p className="mt-1 text-sm text-red-500">
                      Please select a category
                    </p>
                  )}
                </div>

                {selectedCategory && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Event
                    </label>
                    <div className="relative">
                      <select
                        value={selectedOption}
                        onChange={handleOptionChange}
                        className={`${getInputClassName(
                          "event"
                        )} appearance-none`}
                        style={{
                          whiteSpace: "normal",
                          height: "auto",
                          minHeight: "2.5rem",
                        }}
                      >
                        <option value="">Select an event</option>
                        {modCategories
                          .find((c) => c.id === selectedCategory)
                          .options.map((option) => (
                            <option
                              key={option.name}
                              value={option.name}
                              style={{
                                whiteSpace: "normal",
                                overflowWrap: "break-word",
                                wordWrap: "break-word",
                                hyphens: "auto",
                                maxWidth: "100%",
                              }}
                            >
                              {option.name}
                            </option>
                          ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 dark:text-gray-300">
                        <svg
                          className="fill-current h-4 w-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                    {invalidFields.includes("event") && (
                      <p className="mt-1 text-sm text-red-500">
                        Please select an event
                      </p>
                    )}
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Extension Category (optional)
                  </label>
                  <select
                    value={selectedExtensionCategory}
                    onChange={handleExtensionCategoryChange}
                    className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600"
                  >
                    <option value="">
                      Select an extension category (optional)
                    </option>
                    {extensionCategories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                {selectedExtensionCategory && (
                  <div>
                    <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                      Extension (optional)
                    </label>
                    <select
                      value={selectedExtension}
                      onChange={handleExtensionChange}
                      className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600"
                    >
                      <option value="">Select an extension (optional)</option>
                      {extensionCategories
                        .find((c) => c.id === selectedExtensionCategory)
                        .extensions.map((extension, index) => (
                          <option key={index} value={extension}>
                            {extension}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                    Date and Time
                  </label>
                  <div className="flex space-x-2">
                    <input
                      type="date"
                      value={date}
                      onChange={handleDateChange}
                      className={`flex-grow ${getInputClassName("date")}`}
                    />
                    <select
                      value={hour}
                      onChange={(e) => setHour(e.target.value)}
                      className="w-20 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600"
                    >
                      {[...Array(12)].map((_, i) => (
                        <option
                          key={i}
                          value={(i + 1).toString().padStart(2, "0")}
                        >
                          {(i + 1).toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <select
                      value={minute}
                      onChange={(e) => setMinute(e.target.value)}
                      className="w-20 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600"
                    >
                      {[...Array(60)].map((_, i) => (
                        <option key={i} value={i.toString().padStart(2, "0")}>
                          {i.toString().padStart(2, "0")}
                        </option>
                      ))}
                    </select>
                    <select
                      value={ampm}
                      onChange={(e) => setAmpm(e.target.value)}
                      className="w-20 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-600"
                    >
                      <option value="AM">AM</option>
                      <option value="PM">PM</option>
                    </select>
                  </div>
                  {invalidFields.includes("date") && (
                    <p className="mt-1 text-sm text-red-500">
                      Please select a date
                    </p>
                  )}
                </div>

                <div>
                  <div className="flex items-center space-x-4">
                    <button
                      type="button"
                      onClick={() => setShowImageSelector(true)}
                      className="bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors duration-300"
                    >
                      Select Image
                    </button>
                    {backgroundImage && (
                      <img
                        src={backgroundImage}
                        alt="Selected background"
                        className="w-16 h-16 object-cover rounded-md"
                      />
                    )}
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-300"
                >
                  Create Countdown
                </button>
              </form>
            </motion.div>
          </div>

          <div className="hidden md:block w-1/6 bg-gray-200 dark:bg-gray-800"></div>
        </div>

      </div>

      <Footer></Footer>

      <AnimatePresence>
        {showImageSelector && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-3xl w-full max-h-[80vh] overflow-y-auto"
            >
              <div className="grid grid-cols-3 gap-4 mt-8">
                {imageOptions.map((image, index) => (
                  <div
                    key={index}
                    className="aspect-w-1 aspect-h-1 relative overflow-hidden rounded-md cursor-pointer hover:opacity-80 transition-opacity duration-300"
                    onClick={() => handleImageSelect(image)}
                  >
                    <img
                      src={image}
                      alt={`Option ${index + 1}`}
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                  </div>
                ))}
              </div>
              <button
                className="mt-4 bg-gray-300 text-gray-800 py-2 px-4 rounded-md hover:bg-gray-400 transition-colors duration-300"
                onClick={() => setShowImageSelector(false)}
              >
                Cancel
              </button>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showAd && <CenteredAd onClose={handleAdClose} />}
      </AnimatePresence>
    </div>
  );
};

export default CreateEvent;
