import React from "react";
import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 p-4 border-t border-gray-200 dark:border-gray-700">
      <div className="container mx-auto flex flex-col items-center md:flex-row justify-between md:items-center text-center md:text-left space-y-2 md:space-y-0">
        {/* First Row: Links */}
        <div className="flex flex-wrap justify-center space-x-4 text-sm">
          <Link to="/privacy-policy" className="hover:text-blue-500">
            Privacy Policy
          </Link>
          <Link to="/terms" className="hover:text-blue-500">
            Terms
          </Link>
          <Link to="/contact" className="hover:text-blue-500">
            Contact
          </Link>
          <a
            href="https://x.com/daystogogo"
            target="_blank"
            rel="noopener noreferrer"
            className="text-lg hover:text-blue-400"
          >
            <FaTwitter />
          </a>
        </div>

        {/* Second Row: Copyright */}
        <div className="text-sm mt-2 md:mt-0">
          <p>Copyright &copy; 2024 daystogogo.com. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
