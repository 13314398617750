import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import EventCard from '../components/EventCard';
import Footer from '../components/Footer';
import { useTimeZone } from '../App';
import { ThemeContext } from '../components/ThemeContext';
import { parseISO, differenceInSeconds, format } from 'date-fns';

const INITIAL_BATCH_SIZE = 10; // Number of events to load initially

const HomePage = ({ events: initialEvents }) => {
  const { timeZone } = useTimeZone();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [sortedEvents, setSortedEvents] = useState([]);
  const [displayedEvents, setDisplayedEvents] = useState([]);
  const [sortMethod, setSortMethod] = useState('popular');
  const [isLoading, setIsLoading] = useState(true);
  const [hasLoadedAll, setHasLoadedAll] = useState(false);
  const loaderRef = useRef(null);
  
  const sortEvents = useCallback((method, eventsToSort) => {
    let sorted = [...eventsToSort];
    const now = new Date();

    switch (method) {
      case 'shortest':
        sorted.sort((a, b) => {
          const timeA = differenceInSeconds(parseISO(a.date), now);
          const timeB = differenceInSeconds(parseISO(b.date), now);
          return timeA - timeB;
        });
        break;
      case 'longest':
        sorted.sort((a, b) => {
          const timeA = differenceInSeconds(parseISO(a.date), now);
          const timeB = differenceInSeconds(parseISO(b.date), now);
          return timeB - timeA;
        });
        break;
      case 'popular':
      default:
        // Assuming the original order is the popular order
        break;
    }

    return sorted;
  }, []);

  useEffect(() => {
    const sorted = sortEvents(sortMethod, initialEvents);
    setSortedEvents(sorted);
    setDisplayedEvents(sorted.slice(0, INITIAL_BATCH_SIZE));
    setIsLoading(false);
    setHasLoadedAll(false);
  }, [initialEvents, sortMethod, sortEvents]);

  const loadAllEvents = useCallback(() => {
    if (!hasLoadedAll) {
      setDisplayedEvents(sortedEvents);
      setHasLoadedAll(true);
    }
  }, [sortedEvents, hasLoadedAll]);

  const handleSortMethodChange = (method) => {
    setSortMethod(method);
    setIsLoading(true);
    setHasLoadedAll(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isLoading && !hasLoadedAll) {
          loadAllEvents();
        }
      },
      { 
        rootMargin: '200px 0px', // Start loading when the target is 200px away
        threshold: 0 // Trigger as soon as any part of the target becomes visible
      }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loadAllEvents, isLoading, hasLoadedAll]);

  // Function to render event cards
  const renderEventCards = useCallback(() => {
    return displayedEvents.map((event, index) => {
      const eventDate = parseISO(event.date);
      const formattedDate = format(eventDate, "EEE, do MMM yyyy 'at' h:mm a");
      return (
        <motion.div
          key={event.id || `custom-${index}`}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: Math.min(index * 0.05, 1) }} // Cap the delay at 1 second
        >
          <EventCard 
            id={event.id}
            title={event.name} 
            date={event.date}
            formattedDate={formattedDate}
            categoryIndex={event.categoryIndex}
            optionIndex={event.optionIndex}
            extensionCategoryIndex={event.extensionCategoryIndex}
            extensionIndex={event.extensionIndex}
            imageIndex={event.imageIndex}
            backgroundImage={event.image}
            timeZone={timeZone}
          />
        </motion.div>
      );
    });
  }, [displayedEvents, timeZone]);

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
        <div className="fixed top-0 left-0 right-0 z-50">
          <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
        </div>
        
        <div className="pt-40">
          {/* Sorting buttons */}
          <div className="flex justify-center space-x-4 mb-6 pt-4">
            <button
              onClick={() => handleSortMethodChange('popular')}
              className={`px-4 py-2 rounded-md ${
                sortMethod === 'popular'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
              }`}
            >
              Popular
            </button>
            <button
              onClick={() => handleSortMethodChange('shortest')}
              className={`px-4 py-2 rounded-md ${
                sortMethod === 'shortest'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
              }`}
            >
              Shortest
            </button>
            <button
              onClick={() => handleSortMethodChange('longest')}
              className={`px-4 py-2 rounded-md ${
                sortMethod === 'longest'
                  ? 'bg-blue-500 text-white'
                  : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300'
              }`}
            >
              Longest
            </button>
          </div>

          {/* Main content */}
          <div className="flex flex-col md:flex-row">
            <div className="hidden md:block w-1/6 bg-gray-200 dark:bg-gray-800"></div>

            <main className="flex-grow container mx-auto px-4 py-8">
              <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 max-w-5xl mx-auto">
                {isLoading ? (
                  <p>Loading events...</p>
                ) : (
                  renderEventCards()
                )}
              </div>
              {!hasLoadedAll && (
                <div ref={loaderRef} className="h-20" /> // Invisible loader
              )}
            </main>

            <div className="hidden md:block w-1/6 bg-gray-200 dark:bg-gray-800"></div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;