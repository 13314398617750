export const detectTimeZone = () => {
    try {
      const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      localStorage.setItem('userTimeZone', detectedTimeZone);
      return detectedTimeZone;
    } catch (error) {
      console.error('Error detecting time zone:', error);
      return 'UTC'; // Fallback to UTC if detection fails
    }
  };
  
  
  export function getTimeZoneOptions() {
    return Intl.supportedValuesOf('timeZone');
  }