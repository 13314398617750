import { parseISO, getYear, nextDay, addWeeks, addYears, isBefore, addDays } from 'date-fns';

export const updateSpecialDates = (events, timeZone) => {
  const currentYear = getYear(new Date());
  const isAustralian = ['Australia/Sydney', 'Australia/Melbourne', 'Australia/Perth', 'Australia/Brisbane', 'Australia/Adelaide', 'New_Zealand/Auckland'].includes(timeZone);

  return events.map(event => {
    let updatedDate = parseISO(event.date);
    
    switch (event.id) {
      case 'fathers-day-2024':
        updatedDate = calculateFathersDay(currentYear, isAustralian);
        break;
      case 'mothers-day-2024':
        updatedDate = calculateMothersDay(currentYear, isAustralian);
        break;
      case 'winter-solstice-2024':
        updatedDate = calculateWinterSolstice(currentYear, isAustralian);
        break;
        case 'memorial-day-2024':
        updatedDate = calculateMemorialDay(currentYear);
        break;
      case 'national-ice-cream-day-2024':
        updatedDate = calculateNationalIceCreamDay(currentYear);
        break;
      case 'world-sleep-day-2024':
        updatedDate = calculateWorldSleepDay(currentYear);
        break;
      case 'black-friday-2024':
        updatedDate = calculateBlackFriday(currentYear);
        break;
      case 'world-environment-day-2024':
        updatedDate = calculateWorldEnvironmentDay(currentYear);
        break;
      case 'international-friendship-day-2024':
        updatedDate = calculateInternationalFriendshipDay(currentYear);
        break;
      case 'easter-sunday-2024':
        updatedDate = calculateEasterSunday(currentYear);
        break;
      default:
        return event; // Return the event unchanged for all other cases
    }

    // If the calculated date is in the past, add a year
    if (isBefore(updatedDate, new Date())) {
      updatedDate = addYears(updatedDate, 1);
    }

    return {
      ...event,
      date: updatedDate.toISOString().split('T')[0] // Format as YYYY-MM-DD
    };
  });
};

const calculateFathersDay = (year, isAustralian) => {
  if (isAustralian) {
    // First Sunday of September
    const firstDayOfSeptember = new Date(year, 8, 1); // Month is 0-indexed
    return nextDay(firstDayOfSeptember, 0); // 0 represents Sunday
  } else {
    // Third Sunday of June
    const firstDayOfJune = new Date(year, 5, 1);
    const firstSundayOfJune = nextDay(firstDayOfJune, 0);
    return addWeeks(firstSundayOfJune, 2);
  }
};

const calculateMothersDay = (year, isAustralian) => {
  // Second Sunday of May for both Australia and US
  const firstDayOfMay = new Date(year, 4, 1);
  const firstSundayOfMay = nextDay(firstDayOfMay, 0);
  return addWeeks(firstSundayOfMay, 1);
};

const calculateWinterSolstice = (year, isAustralian) => {
  if (isAustralian) {
    return new Date(year, 5, 21); // June 21 for Southern Hemisphere
  } else {
    return new Date(year, 11, 21); // December 21 for Northern Hemisphere
  }
};

const calculateMemorialDay = (year) => {
  // Memorial Day: Last Monday in May
  let date = new Date(year, 4, 31); // May 31st
  while (date.getDay() !== 1) { // 1 represents Monday
    date.setDate(date.getDate() - 1);
  }
  return date;
};

const calculateNationalIceCreamDay = (year) => {
  // National Ice Cream Day: Third Sunday in July
  const firstDayOfJuly = new Date(year, 6, 1); // July 1st
  const firstSunday = nextDay(firstDayOfJuly, 0); // 0 represents Sunday
  return addWeeks(firstSunday, 2); // Third Sunday
};

const calculateWorldSleepDay = (year) => {
  // World Sleep Day: Friday before Spring Vernal Equinox (around March 20th)
  const march20th = new Date(year, 2, 20); // March 20th
  let date = march20th;
  // Find the previous Friday
  while (date.getDay() !== 5) { // 5 represents Friday
    date.setDate(date.getDate() - 1);
  }
  return date;
};

const calculateBlackFriday = (year) => {
  // Black Friday: Day after Thanksgiving (Fourth Thursday in November)
  const firstDayOfNovember = new Date(year, 10, 1); // November 1st
  const firstThursday = nextDay(firstDayOfNovember, 4); // 4 represents Thursday
  const thanksgiving = addWeeks(firstThursday, 3); // Fourth Thursday
  return addDays(thanksgiving, 1); // Day after Thanksgiving
};

const calculateWorldEnvironmentDay = (year) => {
  // World Environment Day: June 5th
  return new Date(year, 5, 5); // June 5th
};

const calculateInternationalFriendshipDay = (year) => {
  // International Friendship Day: July 30th
  return new Date(year, 6, 30); // July 30th
};

const calculateEasterSunday = (year) => {
  const f = Math.floor;
  const G = year % 19;
  const C = f(year / 100);
  const H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30;
  const I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11));
  const J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7;
  const L = I - J;
  const month = 3 + f((L + 40) / 44); // March is month 3, April is month 4
  const day = L + 28 - 31 * f(month / 4);
  return new Date(year, month - 1, day); // Return as Date object (month is 0-indexed)
};