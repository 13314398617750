import React, { useState, useEffect, useCallback, memo } from "react";
import { motion } from "framer-motion";
import { getTimeZoneOptions } from '../utils/timeZone';
import { useTimeZone } from '../App';
import { Link } from 'react-router-dom';

const Navbar = memo(({ toggleTheme, isDarkMode }) => {
  const { timeZone, updateTimeZone } = useTimeZone();
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isTimeZoneModalOpen, setIsTimeZoneModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  // Ensure that the navbar is functional right after the component mounts
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Make sure the navigation buttons are clickable immediately
    const navButtons = document.querySelectorAll('a, button');
    navButtons.forEach(button => button.removeAttribute('disabled'));

    return () => {
      clearInterval(timer);
    };
  }, [timeZone]);

  const formatDate = useCallback((date) => {
    return date.toLocaleDateString(undefined, { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric', 
      timeZone 
    });
  }, [timeZone]);

  const formatTime = useCallback((date) => {
    return date.toLocaleTimeString(undefined, { 
      hour: '2-digit', 
      minute: '2-digit',
      timeZone 
    });
  }, [timeZone]);

  const toggleTimeZoneModal = useCallback(() => {
    setIsTimeZoneModalOpen(prev => !prev);
    setSearchTerm('');
    setSearchResults([]);
  }, []);

  const handleSearch = useCallback((event) => {
    const term = event.target.value;
    setSearchTerm(term);
    
    if (term.length > 0) {
      const results = getTimeZoneOptions().filter(tz => 
        tz.toLowerCase().includes(term.toLowerCase())
      );
      setSearchResults(results.slice(0, 5));
    } else {
      setSearchResults([]);
    }
  }, []);

  const selectTimeZone = useCallback((tz) => {
    updateTimeZone(tz);
    setIsTimeZoneModalOpen(false);
    setSearchTerm('');
    setSearchResults([]);
  }, [updateTimeZone]);

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 120 }}
      className={`relative p-4 md:p-8 fixed w-full z-50 shadow-lg`}
      style={{
        backgroundImage: `url('/backgrounds/dtggBanner.png')`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-30"></div>

      <div className="relative container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="text-white mr-2 flex items-center">
            <motion.img
              src="/clock.png"
              alt="Logo"
              className="h-24 w-auto object-contain"
            />
          </Link>
          <div 
            className="text-white text-sm font-bold cursor-pointer"
            onClick={toggleTimeZoneModal}
          >
            <span>{formatDate(currentTime)}</span>
            <span className="mx-2">|</span>
            <span>{formatTime(currentTime)}</span>
            <span className="mx-2">|</span>
            <span>{timeZone}</span>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Link
            to="/create"
            className={`
              px-4 py-2 rounded-full transition-colors duration-300
              ${isDarkMode
                ? 'bg-blue-600 text-white hover:bg-blue-700'
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }
            `}
          >
            Create
          </Link>
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
            onClick={toggleTheme}
            className="p-2 rounded-full bg-blue-300 text-blue-900 dark:text-black hover:bg-white transition-colors duration-300 shadow-md"
            aria-label={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {isDarkMode ? "🌞" : "🌙"}
          </motion.button>
        </div>
      </div>
      {isTimeZoneModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg w-80">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search for a time zone..."
              className="w-full p-2 mb-2 border rounded"
            />
            <ul className="max-h-60 overflow-y-auto">
              {searchResults.map((tz, index) => (
                <li 
                  key={index} 
                  className="p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={() => selectTimeZone(tz)}
                >
                  {tz}
                </li>
              ))}
            </ul>
            <button 
              onClick={toggleTimeZoneModal}
              className="mt-2 w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </motion.nav>
  );
});

export default Navbar;
