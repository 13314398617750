import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { DateTime } from "luxon";

const CountdownTimer = ({ targetDate, timeZone }) => {
  const [timeLeft, setTimeLeft] = useState({});

  const calculateTimeLeft = () => {
    // Parse the targetDate as midnight in the provided time zone
    const targetDateTime = DateTime.fromISO(`${targetDate}`, {
      zone: timeZone,
    });

    // Get the current time in the specified time zone
    const nowInTimeZone = DateTime.now().setZone(timeZone);

    // Calculate the time difference in milliseconds
    const difference = targetDateTime.diff(nowInTimeZone).milliseconds;

    let timeLeft = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      // Check if the current date matches the target date
      const isSameDay = nowInTimeZone.hasSame(targetDateTime, 'day');
      
      if (isSameDay) {
        // If it's the same day, check if 24 hours have passed
        const hoursPassed = nowInTimeZone.diff(targetDateTime, 'hours').hours;
        
        if (hoursPassed < 24) {
          // If less than 24 hours have passed, keep all values at 0
          timeLeft = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
          };
        } else {
          // If 24 hours have passed, calculate the next occurrence
          const nextOccurrence = targetDateTime.plus({ years: 1 });
          const newDifference = nextOccurrence.diff(nowInTimeZone).milliseconds;

          timeLeft = {
            days: Math.floor(newDifference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((newDifference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((newDifference / 1000 / 60) % 60),
            seconds: Math.floor((newDifference / 1000) % 60),
          };
        }
      }
    }

    return timeLeft;
  };

  useEffect(() => {
    // Update the time left every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, [targetDate, timeZone]);

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <motion.div
      key={interval}
      initial={{ scale: 0.9, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-blue-800 dark:bg-gray-800 rounded-lg p-2 sm:p-4 shadow-lg border border-blue-600 dark:border-blue-300"
    >
      <AnimatePresence mode="wait">
        <motion.span
          key={timeLeft[interval]}
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -20, opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="text-2xl sm:text-4xl font-bold text-blue-300 block"
        >
          {timeLeft[interval]}
        </motion.span>
      </AnimatePresence>
      <p className="text-xs sm:text-sm uppercase text-blue-200 dark:text-gray-400 mt-1">
        {interval}
      </p>
    </motion.div>
  ));

  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4 text-center">
        {timerComponents}
      </div>
      <p className="text-center text-sm text-blue-100 dark:text-gray-100 mt-4">
        Time Zone: {timeZone}
      </p>
    </div>
  );
};

export default CountdownTimer;