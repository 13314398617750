import React, { useEffect, useContext } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { ThemeContext } from "../components/ThemeContext"; // Import ThemeContext

const Contact = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext); // Get isDarkMode from context

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? "dark" : ""}`}>
      <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
      <div className="flex-grow bg-gray-100 dark:bg-gray-900 flex flex-col">
        <div className="container mx-auto px-4 py-8 flex-grow">
          <h1 className="text-3xl font-bold text-blue-900 dark:text-blue-300 mb-4">
            Contact Us
          </h1>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            If you have any questions, concerns, or feedback about DaysToGoGo,
            please don't hesitate to reach out to us:
          </p>
          <ul className="list-disc list-inside mb-4 text-gray-700 dark:text-gray-300">
            <li>Email: daystogogo.com@domainsbyproxy.com</li>
          </ul>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We're based in Australia and will do our best to respond to your
            inquiry as soon as possible.
          </p>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            For questions about our Terms of Service or Privacy Policy, please
            refer to the respective pages or contact us using the email address
            above.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
