import React, { useEffect, useContext } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { ThemeContext } from "../components/ThemeContext"; // Import ThemeContext

const Terms = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext); // Get isDarkMode from ThemeContext

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? "dark" : ""}`}>
      <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
      <div className="flex-grow bg-gray-100 dark:bg-gray-900 flex flex-col">
        <div className="container mx-auto px-4 py-8 flex-grow">
          <h1 className="text-3xl font-bold text-blue-900 dark:text-blue-300 mb-4">
            Terms of Service
          </h1>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            Welcome to DaysToGoGo. By accessing and using this site, you accept
            and agree to be bound by the terms and provisions of this agreement.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            1. Use License
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            Permission is granted to temporarily view the materials (information
            or software) on our website for personal, non-commercial transitory
            viewing only. This is the grant of a license, not a transfer of
            title.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            2. Disclaimer
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            The materials on our website are provided on an 'as is' basis. We
            make no warranties, expressed or implied, and hereby disclaim and
            negate all other warranties including, without limitation, implied
            warranties or conditions of merchantability, fitness for a
            particular purpose, or non-infringement of intellectual property or
            other violation of rights.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            3. Limitations
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            In no event shall we or our suppliers be liable for any damages
            (including, without limitation, damages for loss of data or profit,
            or due to business interruption) arising out of the use or inability
            to use the materials on our website.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            4. Accuracy of Materials
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            The materials appearing on our website could include technical,
            typographical, or photographic errors. We do not warrant that any of
            the materials on our website are accurate, complete, or current.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            5. Links
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We have not reviewed all of the sites linked to our website and are
            not responsible for the contents of any such linked site. The
            inclusion of any link does not imply endorsement by us of the site.
            Use of any such linked website is at the user's own risk.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            6. Modifications
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We may revise these terms of service for our website at any time
            without notice. By using this website you are agreeing to be bound
            by the then current version of these terms of service.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            7. Governing Law
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            These terms and conditions are governed by and construed in
            accordance with the laws of Australia and you irrevocably submit to
            the exclusive jurisdiction of the courts in Australia.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            8. Contact Information
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            If you have any questions about these Terms, please contact us at
            daystogogo.com@domainsbyproxy.com.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
