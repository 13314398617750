// src/data/CategoryData.js

export const categories = [
  {
    id: "personal",
    name: "Personal",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "get married", image: "/backgrounds/default.png" },
      {
        name: "celebrate my wedding anniversary",
        image: "/backgrounds/default.png",
      },
      { name: "graduate", image: "/backgrounds/default.png" },
      { name: "celebrate my birthday", image: "/backgrounds/default.png" },
      { name: "buy a house", image: "/backgrounds/default.png" },
      { name: "adopt a pet", image: "/backgrounds/default.png" },
      { name: "run a marathon", image: "/backgrounds/default.png" },
      { name: "start a new hobby", image: "/backgrounds/default.png" },
      { name: "retire", image: "/backgrounds/default.png" },
      { name: "move to a new city", image: "/backgrounds/default.png" },
    ],
  },

  {
    id: "work",
    name: "Work",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "escape for my lunch break", image: "/backgrounds/default.png" },
      {
        name: "see my boss leave so I can slack off",
        image: "/backgrounds/default.png",
      },
      {
        name: "finish this never-ending meeting",
        image: "/backgrounds/default.png",
      },
      {
        name: "clock out and pretend I don't exist",
        image: "/backgrounds/default.png",
      },
      {
        name: "have my coffee kick in and become productive",
        image: "/backgrounds/default.png",
      },
      {
        name: "receive my paycheck and feel rich for a day",
        image: "/backgrounds/default.png",
      },
      { name: "sneak out early on Friday", image: "/backgrounds/default.png" },
      {
        name: "finish my presentation I haven't started",
        image: "/backgrounds/default.png",
      },
      {
        name: 'get promoted to "Chief Coffee Maker"',
        image: "/backgrounds/default.png",
      },
      {
        name: 'attend another "team building" exercise',
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "age-milestones",
    name: "Age Milestones",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "turn 16 years old", image: "/backgrounds/default.png" },
      { name: "turn 18 years old", image: "/backgrounds/default.png" },
      { name: "turn 20 years old", image: "/backgrounds/default.png" },
      { name: "turn 21 years old", image: "/backgrounds/default.png" },
      { name: "turn 30 years old", image: "/backgrounds/default.png" },
      { name: "turn 40 years old", image: "/backgrounds/default.png" },
      { name: "turn 50 years old", image: "/backgrounds/default.png" },
      { name: "turn 60 years old", image: "/backgrounds/default.png" },
      { name: "turn 70 years old", image: "/backgrounds/default.png" },
      { name: "turn 80 years old", image: "/backgrounds/default.png" },
      { name: "turn 90 years old", image: "/backgrounds/default.png" },
      { name: "turn 100 years old", image: "/backgrounds/default.png" },
    ],
  },
  {
    id: "professional",
    name: "Professional",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "start a new job", image: "/backgrounds/default.png" },
      {
        name: "celebrate my work anniversary",
        image: "/backgrounds/default.png",
      },
      { name: "launch my business", image: "/backgrounds/default.png" },
      { name: "get a promotion", image: "/backgrounds/default.png" },
      { name: "finish a big project", image: "/backgrounds/default.png" },
      { name: "attend a conference", image: "/backgrounds/default.png" },
      { name: "give a presentation", image: "/backgrounds/default.png" },
      { name: "publish a paper", image: "/backgrounds/default.png" },
      { name: "receive an award", image: "/backgrounds/default.png" },
      { name: "start freelancing", image: "/backgrounds/default.png" },
    ],
  },
  {
    id: "officeShenanigans",
    name: "Office Shenanigans",
    options: [
      { name: "master the art of hiding in the bathroom", image: "/backgrounds/default.png" },
      { name: "secretly judge everyone's lunch choices", image: "/backgrounds/default.png" },
      { name: "perfect my fake laugh for awkward jokes", image: "/backgrounds/default.png" },
      { name: "fail to avoid making eye contact with HR", image: "/backgrounds/default.png" },
      { name: "survive another email chain from hell", image: "/backgrounds/default.png" },
      { name: "dodge that impromptu meeting", image: "/backgrounds/default.png" },
      { name: "figure out who stole my stapler", image: "/backgrounds/default.png" },
      { name: "fake working hard when the boss walks by", image: "/backgrounds/default.png" },
      { name: "pretend to understand the new software", image: "/backgrounds/default.png" },
      { name: "close 30 tabs before 5 PM", image: "/backgrounds/default.png" },
      { name: "accidentally hit 'reply all' and panic for an hour", image: "/backgrounds/default.png" },
      { name: "make a spreadsheet that looks important but does nothing", image: "/backgrounds/default.png" },
      { name: "steal someone's chair because it's more comfortable", image: "/backgrounds/default.png" },
      { name: "figure out who keeps microwaving fish", image: "/backgrounds/default.png" },
      { name: "pretend to care about the printer being out of paper", image: "/backgrounds/default.png" },
      { name: "snoop on Slack and accidentally message the wrong channel", image: "/backgrounds/default.png" },
      { name: "fake an urgent call to escape small talk", image: "/backgrounds/default.png" },
      { name: "start a passive-aggressive note war over fridge space", image: "/backgrounds/default.png" },
      { name: "survive the Wi-Fi going down for 5 minutes", image: "/backgrounds/default.png" },
      { name: "make a coffee run just to avoid work", image: "/backgrounds/default.png" },
      { name: "hear Susan’s weekend story... again", image: "/backgrounds/default.png" },
      { name: "survive Carl's loud phone conversations", image: "/backgrounds/default.png" },
      { name: "pretend to be interested in Dave’s new diet", image: "/backgrounds/default.png" },
      { name: "endure Greg’s endless 'fun facts'", image: "/backgrounds/default.png" },
      { name: "fake a smile at Karen's passive-aggressive emails", image: "/backgrounds/default.png" },
      { name: "dodge an awkward elevator ride with Ted", image: "/backgrounds/default.png" },
      { name: "tune out Janet’s 'inspirational' quotes of the day", image: "/backgrounds/default.png" },
      { name: "escape a surprise desk visit from the office gossip", image: "/backgrounds/default.png" },
      { name: "endure the constant pen clicking from across the room", image: "/backgrounds/default.png" },
      { name: "finally respond to Bob’s 'urgent' non-urgent email", image: "/backgrounds/default.png" }
    ]
},
  {
    id: "travel",
    name: "Travel",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "start my vacation", image: "/backgrounds/default.png" },
      { name: "board my flight", image: "/backgrounds/default.png" },
      { name: "go on a cruise", image: "/backgrounds/default.png" },
      { name: "visit a new country", image: "/backgrounds/default.png" },
      { name: "start my road trip", image: "/backgrounds/default.png" },
      { name: "go camping", image: "/backgrounds/default.png" },
      { name: "attend a festival abroad", image: "/backgrounds/default.png" },
      {
        name: "begin a study abroad program",
        image: "/backgrounds/default.png",
      },
      { name: "go backpacking", image: "/backgrounds/default.png" },
      {
        name: "visit a wonder of the world",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "shoppingSpree",
    name: "Shopping Spree",
    options: [
      { name: "max out my husband’s credit card on 'essential' shoes", image: "/backgrounds/default.png" },
      { name: "convince myself that buying 3 more bags is a necessity", image: "/backgrounds/default.png" },
      { name: "buy something expensive just to 'see how it feels'", image: "/backgrounds/default.png" },
      { name: "tell my friends 'it's on me' and regret it instantly", image: "/backgrounds/default.png" },
      { name: "hide shopping bags before my partner gets home", image: "/backgrounds/default.png" },
      { name: "justify a designer handbag as a 'long-term investment'", image: "/backgrounds/default.png" },
      { name: "accidentally buy the same outfit in 4 colors", image: "/backgrounds/default.png" },
      { name: "convince myself that I 'saved money' because of the sale", image: "/backgrounds/default.png" },
      { name: "buy something completely unnecessary because it’s cute", image: "/backgrounds/default.png" },
      { name: "use my husband’s credit card for 'revenge shopping'", image: "/backgrounds/default.png" },
      { name: "buy matching outfits for me and my dog", image: "/backgrounds/default.png" },
      { name: "forget what I came for and leave with 10 other things", image: "/backgrounds/default.png" },
      { name: "pretend I don’t have 5 other black dresses already", image: "/backgrounds/default.png" },
      { name: "splurge on overpriced coffee after a 'tough' shopping day", image: "/backgrounds/default.png" },
      { name: "convince myself I NEED this pair of heels... in every color", image: "/backgrounds/default.png" },
      { name: "treat myself for surviving the week (again)", image: "/backgrounds/default.png" },
      { name: "avoid checking my bank balance after a 'little' spree", image: "/backgrounds/default.png" },
      { name: "tell myself 'this is the last one'... for the 3rd time today", image: "/backgrounds/default.png" },
      { name: "buy my friends drinks to avoid explaining my shopping spree", image: "/backgrounds/default.png" },
      { name: "convince myself this is 'self-care,' not overspending", image: "/backgrounds/default.png" }
    ]
},

  {
    id: "clueless",
    name: "Clueless",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "figure out where I left my keys (again)",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally remember someone’s name right after being introduced",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out how to open a PDF without tech support",
        image: "/backgrounds/default.png",
      },
      {
        name: "stop trying to swipe up on a paper notebook",
        image: "/backgrounds/default.png",
      },
      {
        name: "actually understand the plot of a Christopher Nolan movie",
        image: "/backgrounds/default.png",
      },
      {
        name: "realize that Wi-Fi doesn’t mean “Why-Fi?”",
        image: "/backgrounds/default.png",
      },
      {
        name: "remember to reply to an email the same day I read it",
        image: "/backgrounds/default.png",
      },
      {
        name: "figure out what the “cloud” actually is",
        image: "/backgrounds/default.png",
      },
      {
        name: "stop accidentally liking old photos while stalking someone’s profile",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally get through an IKEA assembly without extra parts",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "education",
    name: "Education",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "start school", image: "/backgrounds/default.png" },
      { name: "take my final exams", image: "/backgrounds/default.png" },
      {
        name: "submit my college application",
        image: "/backgrounds/default.png",
      },
      { name: "submit my thesis", image: "/backgrounds/default.png" },
      {
        name: "start learning a new language",
        image: "/backgrounds/default.png",
      },
      { name: "take a certification exam", image: "/backgrounds/default.png" },
      { name: "attend a workshop", image: "/backgrounds/default.png" },
      { name: "start an online course", image: "/backgrounds/default.png" },
      { name: "defend my dissertation", image: "/backgrounds/default.png" },
      { name: "receive my diploma", image: "/backgrounds/default.png" },
    ],
  },
  {
    id: "health",
    name: "Health & Fitness",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "reach my goal weight", image: "/backgrounds/default.png" },
      { name: "quit smoking", image: "/backgrounds/default.png" },
      {
        name: "complete my fitness challenge",
        image: "/backgrounds/default.png",
      },
      { name: "have my medical checkup", image: "/backgrounds/default.png" },
      { name: "start my new diet", image: "/backgrounds/default.png" },
      { name: "run my first 5K", image: "/backgrounds/default.png" },
      { name: "start yoga classes", image: "/backgrounds/default.png" },
      { name: "begin meditation practice", image: "/backgrounds/default.png" },
      {
        name: "start a new workout routine",
        image: "/backgrounds/default.png",
      },
      {
        name: "achieve 30 days of sobriety",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "armageddon",
    name: "Armageddon Adventures",
    options: [
      {
        name: "find out if my pet rock survives the apocalypse",
        image: "/backgrounds/default.png",
      },
      {
        name: "see if my collection of Dad jokes can save humanity",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover if alien invaders enjoy interpretive dance as much as I do",
        image: "/backgrounds/default.png",
      },
      {
        name: "test if my ability to quote entire movies is useful in a post-apocalyptic world",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out if my doomsday bunker doubles as a great venue for underground raves",
        image: "/backgrounds/default.png",
      },
      {
        name: "see if my years of playing 'The Floor is Lava' prepared me for actual lava",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover if my sourdough starter can survive nuclear winter",
        image: "/backgrounds/default.png",
      },
      {
        name: "test if my zombie apocalypse cardio routine was worth all those leg days",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out if my extensive meme knowledge is the new currency in the wasteland",
        image: "/backgrounds/default.png",
      },
      {
        name: "see if my ability to parallel park impresses the new robot overlords",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover if my mixtape is fire enough to repel an alien invasion",
        image: "/backgrounds/default.png",
      },
      {
        name: "test if my 'end of the world' pickup lines actually work when the world ends",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "overdramatic",
    name: "Overly Dramatic Dilemmas",
    options: [
      {
        name: "recover from the trauma of finding an empty milk carton in the fridge",
        image: "/backgrounds/default.png",
      },
      {
        name: "overcome the existential crisis caused by a bad haircut",
        image: "/backgrounds/default.png",
      },
      {
        name: "rebuild my life after accidentally liking my ex's Instagram post from 3 years ago",
        image: "/backgrounds/default.png",
      },
      {
        name: "face the world again after calling my teacher 'Mom' in front of the whole class",
        image: "/backgrounds/default.png",
      },
      {
        name: "regain my dignity after tripping on nothing while walking down the street",
        image: "/backgrounds/default.png",
      },
      {
        name: "recover from the shock of finding out I've been wearing my shirt inside out all day",
        image: "/backgrounds/default.png",
      },
      {
        name: "muster the courage to check my bank account after a night out",
        image: "/backgrounds/default.png",
      },
      {
        name: "emerge from my blanket fort after binge-watching an entire series and realizing it's over",
        image: "/backgrounds/default.png",
      },
      {
        name: "face reality after realizing I sent that embarrassing email to 'Reply All'",
        image: "/backgrounds/default.png",
      },
      {
        name: "gather strength to open the tupperware that's been in the fridge for... how long?",
        image: "/backgrounds/default.png",
      },
      {
        name: "recover from the heartbreak of my favorite character being killed off in a TV show",
        image: "/backgrounds/default.png",
      },
      {
        name: "rebuild my self-esteem after realizing my zipper has been down all day",
        image: "/backgrounds/default.png",
      },
      {
        name: "emotionally prepare to make a phone call in the age of texting",
        image: "/backgrounds/default.png",
      },
      {
        name: "summon the courage to eat the last slice of pizza that no one else wants to take",
        image: "/backgrounds/default.png",
      },
      {
        name: "recover from the betrayal of my friends starting the next episode without me",
        image: "/backgrounds/default.png",
      },
      {
        name: "face the consequences of telling my hairdresser I don't like the haircut",
        image: "/backgrounds/default.png",
      },
      {
        name: "mentally prepare for the anxiety of someone saying 'We need to talk'",
        image: "/backgrounds/default.png",
      },
      {
        name: "build up the strength to tell the waiter they got my order wrong",
        image: "/backgrounds/default.png",
      },
      {
        name: "recover from the horror of realizing I've been mispronouncing a word my entire life",
        image: "/backgrounds/default.png",
      },
      {
        name: "face the existential dread of having to create a new password that meets all the requirements",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "intergalactic",
    name: "Intergalactic Adventures",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "colonize Mars (hopefully with fewer explosions this time)",
        image: "/backgrounds/default.png",
      },
      {
        name: "launch my car into space (because roads are so last century)",
        image: "/backgrounds/default.png",
      },
      {
        name: "tweet from the Moon (best Wi-Fi ever)",
        image: "/backgrounds/default.png",
      },
      {
        name: "build a Hyperloop to Alpha Centauri (only 4.37 light-years to go!)",
        image: "/backgrounds/default.png",
      },
      {
        name: "mine asteroids for cryptocurrency (SpaceCoin to the moon!)",
        image: "/backgrounds/default.png",
      },
      {
        name: "establish the first interplanetary meme economy",
        image: "/backgrounds/default.png",
      },
      {
        name: 'create a reality show: "Keeping Up With The Martians"',
        image: "/backgrounds/default.png",
      },
      {
        name: "open the first zero-gravity taco truck in orbit",
        image: "/backgrounds/default.png",
      },
      {
        name: "host the Galactic Olympics (100-meter float is the new sprint)",
        image: "/backgrounds/default.png",
      },
      {
        name: "prove the universe is a simulation (and find the cheat codes)",
        image: "/backgrounds/default.png",
      },
      {
        name: "make first contact with aliens (hope they like doge)",
        image: "/backgrounds/default.png",
      },
      {
        name: "terraform Venus (because Mars was too easy)",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "laziness",
    name: "Supreme Lord of Procrastination",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "move my fingers slightly to change the TV channel (current record: 3 days)",
        image: "/backgrounds/default.png",
      },
      {
        name: "evolve into a being of pure energy to avoid doing dishes",
        image: "/backgrounds/default.png",
      },
      {
        name: "perfect the art of sleeping with my eyes open during meetings",
        image: "/backgrounds/default.png",
      },
      {
        name: "train my cat to bring me snacks (he's learning... slowly)",
        image: "/backgrounds/default.png",
      },
      {
        name: "develop telekinesis to grab the remote without moving",
        image: "/backgrounds/default.png",
      },
      {
        name: "invent a time machine to give myself more procrastination time",
        image: "/backgrounds/default.png",
      },
      {
        name: "achieve symbiosis with my couch (we're practically one now)",
        image: "/backgrounds/default.png",
      },
      {
        name: 'write a PhD thesis on "The Art of Doing Nothing"',
        image: "/backgrounds/default.png",
      },
      {
        name: "start my fitness journey (step 1: vigorously nod at workout videos)",
        image: "/backgrounds/default.png",
      },
      {
        name: 'clean my room by redefining "clean" (everything\'s where it wants to be)',
        image: "/backgrounds/default.png",
      },
      {
        name: 'break the world record for "longest time spent deciding what to watch"',
        image: "/backgrounds/default.png",
      },
      {
        name: "reach nirvana through the path of ultimate laziness",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn procrastination into an Olympic sport (training starts... eventually)",
        image: "/backgrounds/default.png",
      },
      {
        name: "master the ability to photosynthesis (eating is too much work)",
        image: "/backgrounds/default.png",
      },
      {
        name: 'perfect my "getting ready to get ready" routine',
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "entertainment",
    name: "Entertainment",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "attend a movie premiere", image: "/backgrounds/default.png" },
      { name: "go to a concert", image: "/backgrounds/default.png" },
      { name: "read a new book release", image: "/backgrounds/default.png" },
      { name: "watch the TV series finale", image: "/backgrounds/default.png" },
      { name: "attend a festival", image: "/backgrounds/default.png" },
      { name: "go to a gaming convention", image: "/backgrounds/default.png" },
      { name: "visit an art exhibition", image: "/backgrounds/default.png" },
      { name: "see a theater performance", image: "/backgrounds/default.png" },
      { name: "attend a comedy show", image: "/backgrounds/default.png" },
      { name: "go to a comic convention", image: "/backgrounds/default.png" },
    ],
  },
  {
    id: "finance",
    name: "Finance",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "pay off my debt", image: "/backgrounds/default.png" },
      { name: "reach my savings goal", image: "/backgrounds/default.png" },
      {
        name: "finally buy my first Bitcoin",
        image: "/backgrounds/default.png",
      },
      {
        name: "sell all my Bitcoin",
        image: "/backgrounds/default.png",
      },
      {
        name: "see DOGE coin gogo to the moon",
        image: "/backgrounds/default.png",
      },
      { name: "have my financial review", image: "/backgrounds/default.png" },
      { name: "buy my first stock", image: "/backgrounds/default.png" },
      { name: "start a new budget", image: "/backgrounds/default.png" },
      { name: "open a retirement account", image: "/backgrounds/default.png" },
      { name: "start a side hustle", image: "/backgrounds/default.png" },
      {
        name: "make my last mortgage payment",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "technology",
    name: "Technology",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "get the new smartphone", image: "/backgrounds/default.png" },
      { name: "update my software", image: "/backgrounds/default.png" },
      { name: "attend a tech conference", image: "/backgrounds/default.png" },
      { name: "launch my app", image: "/backgrounds/default.png" },
      { name: "buy the new gaming console", image: "/backgrounds/default.png" },
      { name: "start a coding bootcamp", image: "/backgrounds/default.png" },
      {
        name: "experience the new VR event",
        image: "/backgrounds/default.png",
      },
      { name: "participate in a hackathon", image: "/backgrounds/default.png" },
      {
        name: "attend a 3D printing exhibition",
        image: "/backgrounds/default.png",
      },
      { name: "set up my smart home", image: "/backgrounds/default.png" },
    ],
  },

  {
    id: "absurd",
    name: "Absurd",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "become a world-renowned avocado whisperer",
        image: "/backgrounds/default.png",
      },
      {
        name: "train a squirrel army to take over the world",
        image: "/backgrounds/default.png",
      },
      {
        name: "invent a gadget that translates baby talk into Shakespeare",
        image: "/backgrounds/default.png",
      },
      {
        name: "get hired as a professional cloud shaper",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince my plants to start paying rent",
        image: "/backgrounds/default.png",
      },
      {
        name: "create a viral dance for penguins",
        image: "/backgrounds/default.png",
      },
      {
        name: "teach a cow to do stand-up comedy",
        image: "/backgrounds/default.png",
      },
      {
        name: "become the first human to moonwalk on Mars",
        image: "/backgrounds/default.png",
      },
      {
        name: "win a staring contest against a goldfish",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince my reflection to stop copying me",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "springbreak",
    name: "Spring Break Shenanigans",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "get a tan that doesn't make me look like a human-shaped lobster",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out if my liver can actually survive a week of 'hydrating' with nothing but margaritas",
        image: "/backgrounds/default.png",
      },
      {
        name: "wake up and remember where I left my dignity (and my shoes) last night",
        image: "/backgrounds/default.png",
      },
      {
        name: "perfect my 'I'm totally not hungover' face for all the family vacation photos",
        image: "/backgrounds/default.png",
      },
      {
        name: "attempt a beach body transformation (step 1: locate the beach, step 2: bring my body)",
        image: "/backgrounds/default.png",
      },
      {
        name: "test the limits of how many people can legally fit in a hotel room meant for two",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally use all those language skills from Spanish class (ordering tacos counts, right?)",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover if it's possible to get sunburned through SPF 1000 sunscreen",
        image: "/backgrounds/default.png",
      },
      {
        name: "experience the joy of spending my entire semester's savings in just one week",
        image: "/backgrounds/default.png",
      },
      {
        name: "see how many free drinks I can get by pretending it's my birthday (every night)",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out if 'what happens in Vegas' really does stay in Vegas (spoiler: it doesn't)",
        image: "/backgrounds/default.png",
      },
      {
        name: "master the art of looking cool while internally panicking about the midterms waiting for me",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover new and creative ways to eat ramen on a balcony with an ocean view",
        image: "/backgrounds/default.png",
      },
      {
        name: "test whether 'hair of the dog' is a legitimate scientific hangover cure",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out how many Instagram stories I can post before all my followers mute me",
        image: "/backgrounds/default.png",
      },
      {
        name: "see if I can convince locals that I'm a famous influencer (spoiler: I can't)",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover just how bad my sunglasses tan line can get",
        image: "/backgrounds/default.png",
      },
      {
        name: "experience the thrill of trying to fit a week's worth of fun into the two days I can actually afford",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out if it's possible to subsist solely on beach bar appetizers and free hotel breakfast",
        image: "/backgrounds/default.png",
      },
      {
        name: "master the art of applying aloe vera to my own back (a true spring break survival skill)",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "boysweekend",
    name: "Boys Weekend Mayhem",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "accidentally start a small fire trying to 'improve' the grill with rocket fuel",
        image: "/backgrounds/default.png",
      },
      {
        name: "wake up in a different state with no memory of how we got there (again)",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover which of us is the 'friend who can talk to animals' after drunkenly adopting a raccoon",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn a simple fishing trip into an accidental Loch Ness Monster hunt",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a 'who can eat the spiciest wing' contest and accidentally summon a fire demon",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince the group that streaking through the golf course is a 'cultural tradition'",
        image: "/backgrounds/default.png",
      },
      {
        name: "accidentally join a biker gang after a misunderstanding at a leather goods store",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn a friendly game of poker into a high-stakes international incident",
        image: "/backgrounds/default.png",
      },
      {
        name: "drunkenly order 500 rubber ducks on Amazon and turn the hotel pool into a 'duck sanctuary'",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a 'Beard-Off' contest that somehow ends with everyone joining a Viking reenactment society",
        image: "/backgrounds/default.png",
      },
      {
        name: "accidentally become local legends after starting a bar fight with our terrible karaoke",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn a simple camping trip into an impromptu Bigfoot hunting expedition",
        image: "/backgrounds/default.png",
      },
      {
        name: "end up on the local news after trying to 'improve' the hotel's hot tub with dish soap",
        image: "/backgrounds/default.png",
      },
      {
        name: "get banned from an all-you-can-eat buffet for 'taking the challenge too literally'",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a prank war that escalates into a full-scale, neighborhood-wide water balloon fight",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "girlsweekend",
    name: "Girls Weekend Gone Wild",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "turn a relaxing spa day into an accidental mud wrestling competition",
        image: "/backgrounds/default.png",
      },
      {
        name: "wake up with matching tattoos we don't remember getting (and can't quite decipher)",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a flash mob in the middle of a fancy restaurant because 'our song' came on",
        image: "/backgrounds/default.png",
      },
      {
        name: "accidentally crash a wedding and end up giving a tearful toast to the bride we've never met",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn wine tasting into a 'who can do the best sommelier impression' contest and get mistaken for actual experts",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a fashion show in the hotel lobby using only items from the lost and found",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince everyone to join a 3 AM yoga session on the beach that turns into a seagull-summoning ritual",
        image: "/backgrounds/default.png",
      },
      {
        name: "accidentally become the stars of a reality TV show after a misunderstanding at a café",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn a cooking class into a 'Worst Chef' competition and somehow impress Gordon Ramsay",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a conga line that snakes through three different clubs and ends at a 24-hour diner",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince the entire group to enter a roller derby tournament with zero prior experience",
        image: "/backgrounds/default.png",
      },
      {
        name: "accidentally adopt six cats from a local shelter during what was supposed to be a quick coffee run",
        image: "/backgrounds/default.png",
      },
      {
        name: "turn a simple night out into an impromptu detective mission to find someone's lost shoe",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a 'DIY spa treatments' session that ends with everyone dyed an interesting shade of blue",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince everyone to join a last-minute skydiving excursion (despite our collective fear of heights)",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "funny",
    name: "Funny",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "finally understand a cryptocurrency meme",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince my dog to stop stealing my snacks",
        image: "/backgrounds/default.png",
      },
      {
        name: "perfect my fake laugh for bad jokes",
        image: "/backgrounds/default.png",
      },
      {
        name: "win an Olympic gold medal in procrastination",
        image: "/backgrounds/default.png",
      },
      {
        name: "find the end of a YouTube rabbit hole",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince myself that kale tastes good",
        image: "/backgrounds/default.png",
      },
      {
        name: "actually stick to a New Year’s resolution",
        image: "/backgrounds/default.png",
      },
      {
        name: "laugh at a dad joke I’ve heard 100 times",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally fold the laundry instead of just living out of the basket",
        image: "/backgrounds/default.png",
      },
      {
        name: "get through an entire day without spilling coffee",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "sillystory",
    name: "SillyStory",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "finally convince my cat to stop stealing my socks and instead start knitting tiny sweaters for the neighborhood squirrels",
        image: "/backgrounds/default.png",
      },
      {
        name: "wake up to find that I’ve accidentally signed up for a reality TV show where I have to live as a penguin for a week and communicate only through dance",
        image: "/backgrounds/default.png",
      },
      {
        name: "invent a device that translates my dog’s barks into Shakespearean sonnets, only to find out he’s been critiquing my taste in movies all along",
        image: "/backgrounds/default.png",
      },
      {
        name: "spend an entire day trying to teach my goldfish to play chess, only to realize that it’s actually me who doesn’t know how to play",
        image: "/backgrounds/default.png",
      },
      {
        name: "accidentally become the leader of a secret society of garden gnomes who believe that I am their chosen one destined to find the magical golden watering can",
        image: "/backgrounds/default.png",
      },
      {
        name: "develop a superpower that allows me to instantly find lost TV remotes, but the catch is that I have to sing “Let It Go” every time it works",
        image: "/backgrounds/default.png",
      },
      {
        name: "wake up one morning to discover that all my houseplants have come to life and are demanding a musical number before they agree to photosynthesize",
        image: "/backgrounds/default.png",
      },
      {
        name: "receive an invitation to Hogwarts, only to find out that my magical power is turning Wi-Fi signals into actual wind that messes up everyone’s hair",
        image: "/backgrounds/default.png",
      },
      {
        name: "stumble upon a time machine in my basement, but it only takes me to last Tuesday, and I have to spend the entire trip explaining what Bitcoin is to cavemen",
        image: "/backgrounds/default.png",
      },
      {
        name: "discover that the moon is actually made of cheese, and now I’m the official taste tester for the intergalactic dairy federation, with my first assignment being a cheese fondue party on Mars",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "politics",
    name: "Political Shenanigans",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "see Donald Trump win the presidential election",
        image: "/backgrounds/default.png",
      },
      {
        name: "see Kamala Harris win the presidential election",
        image: "/backgrounds/default.png",
      },
      {
        name: "witness a third-party candidate actually have a chance",
        image: "/backgrounds/default.png",
      },
      {
        name: "see politicians agree on literally anything",
        image: "/backgrounds/default.png",
      },
      {
        name: "watch Congress pass a bill to make naps mandatory",
        image: "/backgrounds/default.png",
      },
      {
        name: "see the White House converted into a reality TV set",
        image: "/backgrounds/default.png",
      },
      {
        name: "witness the first presidential debate on TikTok",
        image: "/backgrounds/default.png",
      },
      {
        name: "see a meme become a serious policy proposal",
        image: "/backgrounds/default.png",
      },
      {
        name: "watch the State of the Union address turn into a comedy roast",
        image: "/backgrounds/default.png",
      },
      {
        name: "see a politician admit they were wrong (shocking!)",
        image: "/backgrounds/default.png",
      },
      {
        name: "witness the electoral college be decided by a game of rock-paper-scissors",
        image: "/backgrounds/default.png",
      },
      {
        name: "see a filibuster break the world record for longest karaoke session",
        image: "/backgrounds/default.png",
      },
      {
        name: "watch the first interplanetary summit with Martian representatives",
        image: "/backgrounds/default.png",
      },
      {
        name: "see the Supreme Court justices settle a case with a dance-off",
        image: "/backgrounds/default.png",
      },
      {
        name: "witness the national budget be determined by a giant game of Monopoly",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "epicfails",
    name: "EpicFails",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "spend months planning a dream vacation, only to realize I booked the flights for the wrong month",
        image: "/backgrounds/default.png",
      },
      {
        name: "try to impress everyone with my cooking skills, but end up serving a dish so burnt it sets off the smoke alarm in the whole building",
        image: "/backgrounds/default.png",
      },
      {
        name: "train for a marathon, but accidentally sign up for a kids’ fun run and get overtaken by a 6-year-old in a superhero cape",
        image: "/backgrounds/default.png",
      },
      {
        name: "prepare for a job interview by practicing all the answers, only to call the interviewer by the wrong name the entire time",
        image: "/backgrounds/default.png",
      },
      {
        name: "go on a big shopping spree, only to realize at checkout that I left my wallet at home",
        image: "/backgrounds/default.png",
      },
      {
        name: "spend hours cleaning the house for guests, only for them to cancel last minute and spill something on the freshly cleaned carpet",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally finish a home improvement project, only to discover I used the wrong color paint and now the room looks like a circus tent",
        image: "/backgrounds/default.png",
      },
      {
        name: "get dressed up for a fancy event, but realize halfway there that I’m wearing mismatched shoes",
        image: "/backgrounds/default.png",
      },
      {
        name: "plan a surprise party for someone, but accidentally send the invitation to them instead of the guests",
        image: "/backgrounds/default.png",
      },
      {
        name: "attempt to fix something around the house myself, but end up making it worse and have to call a professional anyway",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "ridiculousrituals",
    name: "RidiculousRituals",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "prepare for my day with a 5-hour meditation session, only to fall asleep during the first 5 minutes",
        image: "/backgrounds/default.png",
      },
      {
        name: "spend an entire morning perfecting the art of brewing coffee, only to realize I hate coffee",
        image: "/backgrounds/default.png",
      },
      {
        name: "create an elaborate bedtime routine involving 20 pillows, 10 candles, and a lullaby sung by a choir of cats",
        image: "/backgrounds/default.png",
      },
      {
        name: "start my day by consulting my horoscope, tarot cards, and magic 8-ball, only to end up more confused than when I started",
        image: "/backgrounds/default.png",
      },
      {
        name: "perform a complex skincare routine that makes me look like a glazed donut for the rest of the day",
        image: "/backgrounds/default.png",
      },
      {
        name: "attempt a yoga session in my living room, but spend more time untangling myself than actually stretching",
        image: "/backgrounds/default.png",
      },
      {
        name: "make a smoothie with every superfood in my pantry, only to create something that tastes like grass",
        image: "/backgrounds/default.png",
      },
      {
        name: "spend an hour journaling about my day, but realize I just wrote about the sandwich I had for lunch",
        image: "/backgrounds/default.png",
      },
      {
        name: "do a full workout routine before breakfast, then eat enough pancakes to undo all the exercise",
        image: "/backgrounds/default.png",
      },
      {
        name: "spend 20 minutes choosing an outfit, only to wear the same thing I wore yesterday",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "motivation",
    name: "Motivation",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "finally start my passion project",
        image: "/backgrounds/default.png",
      },
      {
        name: "wake up early every day for a week",
        image: "/backgrounds/default.png",
      },
      { name: "get out of my comfort zone", image: "/backgrounds/default.png" },
      {
        name: "stick to a new healthy habit",
        image: "/backgrounds/default.png",
      },
      {
        name: "finish reading that book I started months ago",
        image: "/backgrounds/default.png",
      },
      {
        name: "reach my monthly fitness goal",
        image: "/backgrounds/default.png",
      },
      {
        name: "organize my workspace once and for all",
        image: "/backgrounds/default.png",
      },
      {
        name: "start journaling every morning",
        image: "/backgrounds/default.png",
      },
      {
        name: "maintain a positive mindset throughout the day",
        image: "/backgrounds/default.png",
      },
      {
        name: "take a leap of faith on something I’ve been putting off",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "futurism",
    name: "Futuristic Shenanigans",
    defaultImage: "/backgrounds/future.png",
    options: [
      {
        name: "upload my consciousness to the cloud (hope there's enough storage for all my memes and cat videos)",
        image: "/backgrounds/future.png",
      },
      {
        name: "attend the first intergalactic meme convention on Mars (featuring a special holographic appearance by Grumpy Cat's great-great-grandkitten)",
        image: "/backgrounds/future.png",
      },
      {
        name: "get my flying car back from the robot mechanic (apparently, it needed an oil change and a software update to fix the anti-gravity hiccups)",
        image: "/backgrounds/future.png",
      },
      {
        name: "celebrate the AI overlords' merciful rule with a mandatory party (all hail the algorithm, may its code be ever bug-free!)",
        image: "/backgrounds/future.png",
      },
      {
        name: "have my first meal entirely made of 3D-printed, lab-grown, organic, free-range pixels seasoned with virtual reality spices",
        image: "/backgrounds/future.png",
      },
      {
        name: "receive my participation trophy for the 2050 Olympic e-sleeping competition (I trained for years by binge-watching netflix)",
        image: "/backgrounds/future.png",
      },
      {
        name: "take delivery of my genetically modified pet dinosaur (I named him Tinysaurus Rex, he's the size of a chihuahua)",
        image: "/backgrounds/future.png",
      },
      {
        name: "get my brain chip upgraded to run Minecraft, but with the new 'real-life' mod that turns the whole world into blocks",
        image: "/backgrounds/future.png",
      },
      {
        name: "attend the grand opening of the first underwater Starbucks on the Moon (I heard they're serving gravity-defying lattes)",
        image: "/backgrounds/future.png",
      },
      {
        name: "finally pay off my student loans (just in time for the heat death of the universe, but at least I'll die debt-free)",
        image: "/backgrounds/future.png",
      },
      {
        name: "have my first conversation with my sentient toaster (it's got some hot gossip about the refrigerator's illicit affair with the microwave)",
        image: "/backgrounds/future.png",
      },
      {
        name: "take a vacation in my personal pocket dimension (it's like a man cave, but with infinite space and its own laws of physics)",
        image: "/backgrounds/future.png",
      },
      {
        name: "attend the Milky Way's first interstellar food truck festival (I'm excited to try deep-fried Martian sand worms)",
        image: "/backgrounds/future.png",
      },
      {
        name: "get fitted for my new exoskeleton suit (it comes with a built-in cappuccino maker and a 'skip awkward social interactions' mode)",
        image: "/backgrounds/future.png",
      },
      {
        name: "enroll in Hogwarts Online for my degree in Quantum Spellcasting and Interdimensional Potion Brewing",
        image: "/backgrounds/future.png",
      },
      {
        name: "participate in the Annual Time Traveler's Convention (I'll be meeting myself from five different timelines for brunch)",
        image: "/backgrounds/future.png",
      },
      {
        name: "have my DNA spliced with a jellyfish to achieve immortality and a cool bioluminescent glow (perfect for raves)",
        image: "/backgrounds/future.png",
      },
      {
        name: "test-drive the new teleportation app (hopefully it doesn't mix up my atoms with a housefly's this time)",
        image: "/backgrounds/future.png",
      },
      {
        name: "get my name legally changed to a string of emojis (🚀🌈🦄 is my first choice, but I'm open to suggestions)",
        image: "/backgrounds/future.png",
      },
      {
        name: "sign up for the first human trial to hibernation on the 300-year journey to Alpha Centauri (wake me up when we get there)",
        image: "/backgrounds/future.png",
      },
    ],
  },
  {
    id: "family",
    name: "Family Fun (and Chaos)",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "finally win an argument with my teenager (I've been training for years)",
        image: "/backgrounds/default.png",
      },
      {
        name: "have a family dinner where no one mentions politics or brings up old grudges",
        image: "/backgrounds/default.png",
      },
      {
        name: "successfully assemble all the IKEA furniture without divorcing my spouse",
        image: "/backgrounds/default.png",
      },
      {
        name: "go on a road trip where nobody asks 'are we there yet?' for at least 5 minutes",
        image: "/backgrounds/default.png",
      },
      {
        name: "have a family game night that doesn't end in a Monopoly-induced civil war",
        image: "/backgrounds/default.png",
      },
      {
        name: "get all the kids to school on time with matching socks (a miracle is about to happen)",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince my parents that I do, in fact, know how to use a computer",
        image: "/backgrounds/default.png",
      },
      {
        name: "have a family photo where everyone's eyes are open and nobody's blinking",
        image: "/backgrounds/default.png",
      },
      {
        name: "decode my toddler's elaborate crayon masterpiece on the living room wall",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out which of my kids is the evil genius who reprogrammed the smart home to only speak Klingon",
        image: "/backgrounds/default.png",
      },
      {
        name: "have a peaceful bath without someone knocking on the door every 30 seconds",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally figure out whose turn it is to do the dishes (we've been debating for weeks)",
        image: "/backgrounds/default.png",
      },
      {
        name: "teach grandma how to use her smartphone without her accidentally buying a yacht online",
        image: "/backgrounds/default.png",
      },
      {
        name: "have a family vacation where we actually relax instead of needing a vacation from our vacation",
        image: "/backgrounds/default.png",
      },
      {
        name: "get everyone to agree on a movie for family movie night (without resorting to bribery)",
        image: "/backgrounds/default.png",
      },
      {
        name: "find out which family member has been secretly feeding the dog under the table (the prime suspect is Dad)",
        image: "/backgrounds/default.png",
      },
      {
        name: "have a full conversation with my spouse without being interrupted by a child, pet, or appliance beeping",
        image: "/backgrounds/default.png",
      },
      {
        name: "successfully hide the last piece of cake from my family's dessert-seeking radar",
        image: "/backgrounds/default.png",
      },
      {
        name: "convince my kids that vegetables are actually alien superfoods that grant superpowers",
        image: "/backgrounds/default.png",
      },
      {
        name: "organize a family talent show where Dad promises not to do his 'pull my finger' trick",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "resolutions",
    name: "Resolutions (This Time For Real)",
    defaultImage: "/backgrounds/default.png",
    options: [
      {
        name: "actually use my gym membership (for something other than the free pizza days)",
        image: "/backgrounds/default.png",
      },
      {
        name: "learn a new language (does Klingon count as a professional skill?)",
        image: "/backgrounds/default.png",
      },
      {
        name: "start meal prepping (microwaving different frozen dinners each day counts, right?)",
        image: "/backgrounds/default.png",
      },
      {
        name: "reduce my screen time (right after I finish this next episode... and the one after that)",
        image: "/backgrounds/default.png",
      },
      {
        name: "save money (by switching from designer coffee to just slightly less expensive designer coffee)",
        image: "/backgrounds/default.png",
      },
      {
        name: "read more books (does endlessly scrolling through book quotes on social media count?)",
        image: "/backgrounds/default.png",
      },
      {
        name: "quit procrastinating (I'll start tomorrow, or maybe the day after)",
        image: "/backgrounds/default.png",
      },
      {
        name: "learn to cook (mastering the art of not burning toast is the first step)",
        image: "/backgrounds/default.png",
      },
      {
        name: "organize my closet (by stuffing everything into a bigger closet)",
        image: "/backgrounds/default.png",
      },
      {
        name: "start a successful side hustle (selling my collection of rare memes and cat videos)",
        image: "/backgrounds/default.png",
      },
      {
        name: "practice mindfulness (by being fully aware of how much time I waste on social media)",
        image: "/backgrounds/default.png",
      },
      {
        name: "reduce my carbon footprint (by crop dusting less in public spaces)",
        image: "/backgrounds/default.png",
      },
      {
        name: "improve my sleep schedule (Netflix autoplay is my new lullaby)",
        image: "/backgrounds/default.png",
      },
      {
        name: "drink more water (does coffee count? It's basically bean water)",
        image: "/backgrounds/default.png",
      },
      {
        name: "learn a new skill (professional couch athlete is a skill, right?)",
        image: "/backgrounds/default.png",
      },
      {
        name: "declutter my life (by buying more storage containers to hide the clutter)",
        image: "/backgrounds/default.png",
      },
      {
        name: "improve my posture (by slouching in ergonomic chairs instead of regular ones)",
        image: "/backgrounds/default.png",
      },
      {
        name: "start journaling (tweeting my daily thoughts counts as micro-journaling)",
        image: "/backgrounds/default.png",
      },
      {
        name: "manage stress better (by strategically planning my meltdowns in advance)",
        image: "/backgrounds/default.png",
      },
      {
        name: "become a morning person (11:59 AM is technically still morning)",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "levelup",
    name: "LevelUp",
    defaultImage: "/backgrounds/default.png",
    options: [
      { name: "complete an online course", image: "/backgrounds/default.png" },
      {
        name: "learn a new skill in a month",
        image: "/backgrounds/default.png",
      },
      {
        name: "reach the next level in my career",
        image: "/backgrounds/default.png",
      },
      {
        name: "finish a challenging project ahead of schedule",
        image: "/backgrounds/default.png",
      },
      {
        name: "earn a certification that boosts my resume",
        image: "/backgrounds/default.png",
      },
      {
        name: "master a difficult task that once felt impossible",
        image: "/backgrounds/default.png",
      },
      {
        name: "surpass my fitness milestone",
        image: "/backgrounds/default.png",
      },
      {
        name: "unlock a new personal achievement",
        image: "/backgrounds/default.png",
      },
      {
        name: "finally beat that video game boss",
        image: "/backgrounds/default.png",
      },
      {
        name: "achieve my next big life goal",
        image: "/backgrounds/default.png",
      },
    ],
  },
  {
    id: "holidays",
    name: "Holidays",
    defaultImage: "/backgrounds/default-holiday.png",
    options: [
      {
        id: "new-years-2024",
        name: "New Year's Day",
        date: "2024-01-01",
        description: "Celebrate the beginning of 2025",
        image: "/backgrounds/cellarbrate.png",
      },
      {
        id: "christmas-2024",
        name: "Christmas Day", 
        date: "2024-12-25",
        description: "Christian holiday celebrating birth of Jesus",
        image: "/backgrounds/christmas.png",
      },
      {
        id: "valentine-2024",
        name: "Valentine's Day",
        date: "2025-02-14",
        description:
          "A day of love and affection, share with your husband so he does not forget!",
        image: "/backgrounds/valentines.png",
      },
      {
        id: "st-patrick-2024",
        name: "St. Patrick's Day",
        date: "2025-03-17",
        description: "Irish cultural celebration",
        image: "/backgrounds/stPatricks.png",
      },
      {
        id: "easter-sunday-2024",
        name: "Easter Sunday",
        date: "2025-03-31",
        description: "Christian holiday celebrating resurrection",
        image: "/backgrounds/easter.png",
      },
      {
        id: "mothers-day-2024",
        name: "Mother's Day",
        date: "2025-05-12",
        description: "Honoring mothers and motherhood",
        image: "/backgrounds/mothersDay.png",
      },
      {
        id: "independence-day-2024",
        name: "Independence Day",
        date: "2025-07-04",
        description: "US Independence Day celebration",
        image: "/backgrounds/unitedStates.png",
      },
      {
        id: "halloween-2024",
        name: "Halloween",
        date: "2023-10-31",
        description: "Spooky celebrations and costumes",
        image: "/backgrounds/halloween.png",
      },
      {
        id: "thanksgiving-2024",
        name: "Thanksgiving Day",
        date: "2024-11-28",
        description: "Day of giving thanks (US)",
        image: "/backgrounds/thanksGiving.png",
      },
      {
        id: "fathers-day-2024",
        name: "Fathers Day",
        date: "2024-09-01",
        description: "Honoring fathers and fatherhood",
        image: "/backgrounds/fathersDay.png",
      },
      {
        id: 'chinese-new-year-2025',
        name: "Chinese New Year",
        date: "2025-01-29",
        description: "Chinese cultural celebration of the Lunar New Year",
        image: "/backgrounds/chineseNewYear.png"
      },
      {
        id: 'world-chocolate-day-2024',
        name: "World Chocolate Day",
        date: "2024-07-07",
        description: "A day to enjoy and celebrate chocolate",
        image: "/backgrounds/worldChocolateDay.png"
      },
      {
        id: 'world-emoji-day-2024',
        name: "World Emoji Day",
        date: "2024-07-17",
        description: "Celebrating the fun and use of emojis",
        image: "/backgrounds/worldEmojiDay.png"
      },
      {
        id: 'national-pizza-day-2024',
        name: "National Pizza Day",
        date: "2024-02-09",
        description: "Celebrate everyone's favorite food, pizza!",
        image: "/backgrounds/nationalPizzaDay.png"
      },
      {
        id: 'talk-like-a-pirate-day-2024',
        name: "International Talk Like a Pirate Day",
        date: "2024-09-19",
        description: "A fun day to speak like a pirate",
        image: "/backgrounds/talkLikeAPirateDay.png"
      },
      {
        id: 'world-laughter-day-2024',
        name: "World Laughter Day",
        date: "2024-05-05",
        description: "Promoting laughter for health and positivity",
        image: "/backgrounds/worldLaughterDay.png"
      },
      {
        id: 'national-doughnut-day-2024',
        name: "National Doughnut Day",
        date: "2024-06-07",
        description: "A day to enjoy delicious doughnuts",
        image: "/backgrounds/nationalDoughnutDay.png"
      },
      {
        id: 'international-womens-day-2024',
        name: "International Women's Day",
        date: "2024-03-08",
        description: "Celebrating women's achievements and advocating for gender equality",
        image: "/backgrounds/internationalWomensDay.png"
      },
      {
        id: 'world-penguin-day-2024',
        name: "World Penguin Day",
        date: "2024-04-25",
        description: "Raising awareness and celebrating penguins",
        image: "/backgrounds/worldPenguinDay.png"
      },
      {
        id: 'shark-week-2024',
        name: "Shark Week",
        date: "2024-07-14",
        description: "A week dedicated to learning about sharks",
        image: "/backgrounds/sharkWeek.png"
      },
      {
        id: 'world-cat-day-2024',
        name: "World Cat Day",
        date: "2024-08-08",
        description: "Celebrate and appreciate our feline friends",
        image: "/backgrounds/worldCatDay.png"
      },
      {
        id: 'national-ice-cream-day-2024',
        name: "National Ice Cream Day",
        date: "2024-07-21",
        description: "Indulge in your favorite ice cream flavors",
        image: "/backgrounds/nationalIceCreamDay.png"
      },
      {
        id: 'world-kindness-day-2024',
        name: "World Kindness Day",
        date: "2024-11-13",
        description: "Encouraging acts of kindness and positivity",
        image: "/backgrounds/worldKindnessDay.png"
      },
      {
        id: 'national-hugging-day-2024',
        name: "National Hugging Day",
        date: "2024-01-21",
        description: "Encouraging people to hug loved ones and pets",
        image: "/backgrounds/nationalHuggingDay.png"
      },
      {
        id: 'international-coffee-day-2024',
        name: "International Coffee Day",
        date: "2024-10-01",
        description: "Celebrating coffee lovers and coffee culture",
        image: "/backgrounds/internationalCoffeeDay.png"
      },
      {
        id: 'pi-day-2024',
        name: "Pi Day",
        date: "2024-03-14",
        description: "A fun day to celebrate the mathematical constant π (Pi)",
        image: "/backgrounds/piDay.png"
      },
      {
        id: 'world-sleep-day-2024',
        name: "World Sleep Day",
        date: "2024-03-15",
        description: "Raising awareness about the importance of sleep",
        image: "/backgrounds/worldSleepDay.png"
      },
      {
        id: 'national-taco-day-2024',
        name: "National Taco Day",
        date: "2024-10-04",
        description: "Celebrate by enjoying delicious tacos",
        image: "/backgrounds/nationalTacoDay.png"
      },
      {
        id: 'world-sandwich-day-2024',
        name: "World Sandwich Day",
        date: "2024-11-03",
        description: "A day to enjoy and celebrate sandwiches",
        image: "/backgrounds/worldSandwichDay.png"
      },
      {
        id: 'world-ufo-day-2024',
        name: "World UFO Day",
        date: "2024-07-02",
        description: "A fun day for UFO enthusiasts",
        image: "/backgrounds/worldUfoDay.png"
      },
      {
        id: 'earth-day-2024',
        name: "Earth Day",
        date: "2024-04-22",
        description: "A day to demonstrate support for environmental protection",
        image: "/backgrounds/earthDay.png"
      },
      {
        id: 'world-book-day-2024',
        name: "World Book Day",
        date: "2024-04-23",
        description: "Celebrating books and promoting reading",
        image: "/backgrounds/worldBookDay.png"
      },
      {
        id: 'star-wars-day-2024',
        name: "Star Wars Day",
        date: "2024-05-04",
        description: "May the Fourth be with you! A day for Star Wars fans",
        image: "/backgrounds/starWarsDay.png"
      },
      {
        id: 'world-environment-day-2024',
        name: "World Environment Day",
        date: "2024-06-05",
        description: "Encouraging awareness and action for the protection of the environment",
        image: "/backgrounds/worldEnvironmentDay.png"
      },
      {
        id: 'international-friendship-day-2024',
        name: "International Friendship Day",
        date: "2024-07-30",
        description: "Celebrating the importance of friendships in our lives",
        image: "/backgrounds/internationalFriendshipDay.png"
      },
      {
        id: 'international-beer-day-2024',
        name: "International Beer Day",
        date: "2024-08-02",
        description: "A global celebration of beer, brewing, and community",
        image: "/backgrounds/internationalBeerDay.png"
      },
      {
        id: 'international-dog-day-2024',
        name: "International Dog Day",
        date: "2024-08-26",
        description: "Honoring dogs and promoting adoption",
        image: "/backgrounds/internationalDogDay.png"
      },
      {
        id: 'world-teachers-day-2024',
        name: "World Teachers' Day",
        date: "2024-10-05",
        description: "Recognizing and appreciating teachers worldwide",
        image: "/backgrounds/worldTeachersDay.png"
      },
      {
        id: 'world-mental-health-day-2024',
        name: "World Mental Health Day",
        date: "2024-10-10",
        description: "Raising awareness of mental health issues around the world",
        image: "/backgrounds/worldMentalHealthDay.png"
      },
      {
        id: 'world-vegetarian-day-2024',
        name: "World Vegetarian Day",
        date: "2024-10-01",
        description: "Promoting the joy and benefits of vegetarianism",
        image: "/backgrounds/worldVegetarianDay.png"
      },
      {
        id: 'international-mens-day-2024',
        name: "International Men's Day",
        date: "2024-11-19",
        description: "Celebrating positive male role models and raising awareness of men's issues",
        image: "/backgrounds/internationalMensDay.png"
      },
      {
        id: 'winter-solstice-2024',
        name: "Winter Solstice",
        date: "2024-12-21",
        description: "The shortest day of the year in the Northern Hemisphere",
        image: "/backgrounds/winterSolstice.png"
      },
      {
        id: 'world-photography-day-2024',
        name: "World Photography Day",
        date: "2024-08-19",
        description: "Celebrating the art, craft, science, and history of photography",
        image: "/backgrounds/worldPhotographyDay.png"
      },
      {
        id: 'world-animal-day-2024',
        name: "World Animal Day",
        date: "2024-10-04",
        description: "A day of action for animal rights and welfare",
        image: "/backgrounds/worldAnimalDay.png"
      },
      {
        id: 'memorial-day-2024',
        name: "Memorial Day",
        date: "2024-05-27", // Last Monday in May
        description: "Honoring those who have died in military service in the USA",
        image: "/backgrounds/memorialDay.png"
      },
      {
        id: 'black-friday-2024',
        name: "Black Friday",
        date: "2024-11-29", // Day after Thanksgiving
        description: "The biggest shopping day of the year, with huge discounts",
        image: "/backgrounds/blackFriday.png"
      },
      {
        id: 'international-sushi-day-2024',
        name: "International Sushi Day",
        date: "2024-06-18",
        description: "A celebration of sushi lovers around the world",
        image: "/backgrounds/internationalSushiDay.png"
      },
      {
        id: 'groundhog-day-2024',
        name: "Groundhog Day",
        date: "2024-02-02",
        description: "A tradition to predict the arrival of spring based on a groundhog's shadow",
        image: "/backgrounds/groundhogDay.png"
      },
      {
        id: 'earth-hour-2024',
        name: "Earth Hour",
        date: "2025-03-29T20:30:00", 
        description: "A global movement to switch off lights for an hour in support of the planet",
        image: "/backgrounds/earthHour.png"
      },
      {
        id: 'world-blood-donor-day-2024',
        name: "World Blood Donor Day",
        date: "2024-06-14",
        description: "A day to raise awareness about the importance of blood donation",
        image: "/backgrounds/worldBloodDonorDay.png"
      },
      {
        id: 'world-refugee-day-2024',
        name: "World Refugee Day",
        date: "2024-06-20",
        description: "Honoring the strength and courage of refugees worldwide",
        image: "/backgrounds/worldRefugeeDay.png"
      },
      {
        id: 'world-music-day-2024',
        name: "World Music Day",
        date: "2024-06-21",
        description: "A celebration of music in all its forms across the world",
        image: "/backgrounds/worldMusicDay.png"
      },
      {
        id: 'international-hotdog-day-2024',
        name: "International Hotdog Day",
        date: "2024-07-17", // Typically the third Wednesday in July
        description: "A day to enjoy hotdogs and celebrate this beloved food",
        image: "/backgrounds/internationalHotdogDay.png"
      },
      {
        id: 'world-youth-day-2024',
        name: "World Youth Day",
        date: "2024-08-12",
        description: "A day to empower and recognize the contributions of youth worldwide",
        image: "/backgrounds/worldYouthDay.png"
      },
      {
        id: 'world-turtle-day-2024',
        name: "World Turtle Day",
        date: "2024-05-23",
        description: "Raising awareness for the protection of turtles and tortoises",
        image: "/backgrounds/worldTurtleDay.png"
      },
      {
        id: 'world-cancer-day-2024',
        name: "World Cancer Day",
        date: "2024-02-04",
        description: "A day to raise awareness and encourage the prevention and treatment of cancer",
        image: "/backgrounds/worldCancerDay.png"
      },
      {
        id: 'international-jazz-day-2024',
        name: "International Jazz Day",
        date: "2024-04-30",
        description: "Celebrating jazz and its role in uniting people around the world",
        image: "/backgrounds/internationalJazzDay.png"
      },
      {
        id: 'international-chess-day-2024',
        name: "International Chess Day",
        date: "2024-07-20",
        description: "A day to celebrate the game of chess and its global community",
        image: "/backgrounds/internationalChessDay.png"
      }
      
    ],
  },
];

export const imageOptions = [
  "/backgrounds/default.png",
  "/backgrounds/bar.png",
  "/backgrounds/cat.png",
  "/backgrounds/plane.png",
  "/backgrounds/circus.png",
  "/backgrounds/roadTrip.png",
  "/backgrounds/houseParty.png",
  "/backgrounds/nightclub.png",
  "/backgrounds/lion.png",
  "/backgrounds/powerUp.png",
  "/backgrounds/code.png",
  "/backgrounds/shoppingSpree.png",
  "/backgrounds/lambo.png",
  "/backgrounds/office.png",
  "/backgrounds/doge.png",
  "/backgrounds/map.png",
  "/backgrounds/island.png",
  "/backgrounds/llama.png",
  "/backgrounds/cellarbrate.png",
  "/backgrounds/stPatricks.png",
  "/backgrounds/valentines.png",
  "/backgrounds/mothersDay.png",
  "/backgrounds/fathersDay.png",
  "/backgrounds/halloween.png",
  "/backgrounds/thanksGiving.png",
  "/backgrounds/unitedStates.png",
  "/backgrounds/christmas.png",
  "/backgrounds/easter.png",
  "/backgrounds/chineseNewYear.png",
  "/backgrounds/worldChocolateDay.png",
  "/backgrounds/worldEmojiDay.png",
  "/backgrounds/nationalPizzaDay.png",
  "/backgrounds/talkLikeAPirateDay.png",
  "/backgrounds/worldLaughterDay.png",
  "/backgrounds/nationalDoughnutDay.png",
  "/backgrounds/internationalWomensDay.png",
  "/backgrounds/worldPenguinDay.png",
  "/backgrounds/sharkWeek.png",
  "/backgrounds/worldCatDay.png",
  "/backgrounds/nationalIceCreamDay.png",
  "/backgrounds/worldKindnessDay.png",
  "/backgrounds/nationalHuggingDay.png",
  "/backgrounds/internationalCoffeeDay.png",
  "/backgrounds/piDay.png",
  "/backgrounds/worldSleepDay.png",
  "/backgrounds/nationalTacoDay.png",
  "/backgrounds/worldSandwichDay.png",
  "/backgrounds/worldUfoDay.png",
  "/backgrounds/earthDay.png",
  "/backgrounds/worldBookDay.png",
  "/backgrounds/starWarsDay.png",
  "/backgrounds/worldEnvironmentDay.png",
  "/backgrounds/internationalFriendshipDay.png",
  "/backgrounds/internationalBeerDay.png",
  "/backgrounds/internationalDogDay.png",
  "/backgrounds/worldTeachersDay.png",
  "/backgrounds/worldMentalHealthDay.png",
  "/backgrounds/worldVegetarianDay.png",
  "/backgrounds/internationalMensDay.png",
  "/backgrounds/winterSolstice.png",
  "/backgrounds/worldPhotographyDay.png",
  "/backgrounds/worldAnimalDay.png",
  "/backgrounds/memorialDay.png",
  "/backgrounds/blackFriday.png",
  "/backgrounds/internationalSushiDay.png",
  "/backgrounds/groundhogDay.png",
  "/backgrounds/earthHour.png",
  "/backgrounds/worldBloodDonorDay.png",
  "/backgrounds/worldRefugeeDay.png",
  "/backgrounds/worldMusicDay.png",
  "/backgrounds/internationalHotdogDay.png",
  "/backgrounds/worldYouthDay.png",
  "/backgrounds/worldTurtleDay.png",
  "/backgrounds/worldCancerDay.png",
  "/backgrounds/internationalJazzDay.png",
  "/backgrounds/internationalChessDay.png",
];

export const extensionCategories = [
  {
    id: "husband",
    name: "Husband",
    extensions: [
      "because my husband thinks it's a new video game",
      "because my husband lost a bet to the neighbor's cat",
      "because my husband thinks it's a surprise party for him (it's not)",
      "because my husband's sure it's the start of the robot uprising",
      "because my husband thinks it's a new CrossFit challenge",
      "because my husband's magic 8-ball gave the green light",
      "because my husband's sure it's part of his super-secret surprise party",
      "because my husband's still looking for that thing I asked him to find last week",
      "because my husband thinks he can assemble IKEA furniture without instructions",
      "because my husband's convinced he's discovered a new species of dust bunny",
      "because my husband's treating it like the Super Bowl of grocery shopping",
      "because my husband's trying to convince me it's a 'man cave essential'",
    ],
  },
  {
    id: "wife",
    name: "Wife",
    extensions: [
      "and my wife's already planning how to remodel it",
      "and my wife's excited to add it to her conspiracy board",
      "and my wife's already picked out matching outfits for us",
      "and my wife's prepared a 50-slide presentation on why it's a bad idea",
      "and my wife's already chosen our couple costume theme",
      "and my wife's bringing her collection of true crime podcasts for ambiance",
      "and my wife's already meal-prepped for the next month, just in case",
      "and my wife's prepared a bingo card of things that might go wrong",
      "and my wife misheard 'date night' as 'debate night'",
      "and my wife's creating a Pinterest board for it as we speak",
      "and my wife's already coordinated it with our zodiac signs",
      "and my wife's treating it like a season finale of her favorite show",
    ],
  },
  {
    id: "girlfriend",
    name: "Girlfriend",
    extensions: [
      "but my girlfriend says I need to level up first",
      "but only if my girlfriend approves my outfit first",
      "but my girlfriend's astrology app says it's a bad idea",
      "but my girlfriend's bringing her emotional support cactus",
      "but my girlfriend's writing fanfiction about it as we speak",
      "but my girlfriend's treating it like a season finale",
      "but my girlfriend's consulting her crystals first",
      "but my girlfriend's live-tweeting the entire experience",
      "but my girlfriend's creating a vision board for it",
      "but my girlfriend's insisting on a pre-event photoshoot",
      "but my girlfriend's already planned our couple's costume",
      "but my girlfriend's syncing it with her moon cycle app",
    ],
  },
  {
    id: "boyfriend",
    name: "Boyfriend",
    extensions: [
      "and my boyfriend's bringing his entire Funko Pop collection",
      "and my boyfriend's treating it like the next Olympics",
      "and my boyfriend's planning to document it for his non-existent vlog",
      "and my boyfriend's convinced it's all part of an elaborate flash mob",
      "and my boyfriend's planning our escape route... just in case",
      "and my boyfriend's practicing his 'surprised face' in the mirror",
      "and my boyfriend's bringing his guitar (nobody asked him to)",
      "and my boyfriend's trying to turn it into a video game stream",
      "and my boyfriend's insisting on wearing his 'lucky' unwashed jersey",
      "and my boyfriend's prepared a playlist longer than the event itself",
      "and my boyfriend's treating it like a scene from his favorite action movie",
      "and my boyfriend's bringing his entire comic book collection for 'show and tell'",
      "and my boyfriend still can't seem to hit the toilet bowl (how hard can it be?)",
    ],
  },
  {
    id: "genz",
    name: "Gen Z",
    extensions: [
      "and it's about to be bussin', no cap",
      "but first, let me do it for the TikTok",
      "because the vibes are immaculate",
      "and I'm lowkey stressed but highkey excited",
      "but watch me fumble this bag",
      "and it's giving main character energy",
      "but I might ghost halfway through",
      "because I'm built different",
      "and it's about to slap harder than my mom's flip flop",
      "but the simulation is glitching",
      "and I'm ready to catch these hands if necessary",
      "because my vibe attracts my tribe",
      "and it's living rent-free in my head",
      "but first, let me manifest this",
      "because it's a flex, and I'm here for it",
      "and it's giving 'I don't want peace, I want problems always'",
      "but watch me speedrun this like it's Minecraft",
      "and it's time to secure this W",
      "because I accidentally liked their Instagram post from 2016",
      "and I'm ready to risk it all for the aesthetic",
      "and I'm ready to make this my Roman Empire",
    ],
  },
  {
    id: "boomer",
    name: "OK Boomer",
    extensions: [
      "but first, let me complain about how music these days is just noise",
      "and I'm ready to tell everyone how I bought my first house for $5000",
      "but I need to ask my grandkid how to turn on the computer again",
      "and I'm prepared to lecture youngsters about the value of a firm handshake",
      "but only after I finish clipping coupons from the newspaper",
      "and I'm all set to reminisce about the 'good old days' for the 100th time",
      "but I need to adjust the thermostat first (it's always too cold)",
      "and I'm ready to share unsolicited advice with every millennial I meet",
      "but only if I can pay in exact change",
      "and I'm prepared to explain why Facebook is the peak of technology",
      "but I need to forward that chain email to 10 friends first",
      "and I'm all set to complain about how kids these days are always on their phones",
    ],
  },

  {
    id: "genx",
    name: "Generation X",
    extensions: [
      "but first, let me perfect my eye roll at both Boomers and Millennials",
      "and I'm ready to explain why the 90s were the best decade for everything",
      "but only after I finish this ironic cross-stitch of a meme",
      "and I'm prepared to defend my extensive CD collection as 'vintage'",
      "but I need to update my LinkedIn while pretending I don't care about social media",
      "and I'm all set to complain about adulting before it was cool to complain about adulting",
      "but only if I can wear my favorite flannel shirt",
      "and I'm ready to explain to my kids why MTV used to play music videos",
      "but first, let me finish this argument in the YouTube comments section",
      "and I'm prepared to start another side hustle because why not",
      "but only after I've binged this 90s sitcom for the 100th time",
      "and I'm ready to be cynical about everything while secretly being optimistic",
    ],
  },
  {
    id: "geny",
    name: "Millennials",
    extensions: [
      "but first, let me take a selfie with this vintage filter",
      "and I'm ready to explain why I can't afford a house (it's the avocado toast)",
      "but only after I check if my houseplants are emotionally fulfilled",
      "and I'm prepared to start a podcast about my quarter-life crisis",
      "but I need to find the perfect GIF to express my feelings first",
      "and I'm all set to turn my passion for memes into a viable career option",
      "but only if it's ethical, sustainable, and Instagram-worthy",
      "and I'm ready to explain to my parents why I need 37 open browser tabs",
      "but first, let me add another $5 to my retirement fund",
      "and I'm prepared to defend my participation trophies with fierce mediocrity",
      "but only after I've found the perfect work-life-social media balance",
      "and I'm ready to be nostalgic about the early 2000s while dreading the future",
    ],
  },
  {
    id: "karen",
    name: "Karen",
    extensions: [
      "and Karen's demanding to see life's terms and conditions",
      "and Karen's planning to speak to the manager of time itself",
      "and Karen's bringing her 'Complaint of the Day' calendar",
      "and Karen's prepared a PowerPoint on 'Why I'm Always Right'",
      "and Karen's bringing her collection of expired coupons, just in case",
      "and Karen's ready to argue with a brick wall if necessary",
      "and Karen's bringing her 'Jump to Conclusions' mat",
      "but Karen wants to speak to the manager first",
      "and I'm channeling my inner Karen for this one",
      "and Karen's bringing her collection of Live, Laugh, Love signs",
      "and Karen's planning to review it on Yelp",
      "and I'm hoping Karen doesn't bring her essential oils",
      "and Karen's ready to speak to the manager of life",
      "and Karen's bringing her emotional support chihuahua",
      "and Karen's demanding a refund on 2020",
      "and Karen's writing a strongly worded letter to the universe",
      "and Karen's ready to live-tweet the whole experience",
      "and Karen's planning to leave a 1-star review for the solar system",
      "and Karen's bringing her 'Manager Summoning' bell",
    ],
  },
  {
    id: "john",
    name: "John",
    extensions: [
      "because John accidentally agreed while sleep-talking",
      "but John's still looking for his other sock",
      "because John's imaginary friend triple-dog-dared him",
      "but John's worried his plants will miss him",
      "because John's fortune cookie said it's his lucky day",
      "but John's still trying to decipher the IKEA instructions",
      "because John's toast landed butter-side up this morning",
      "but John's considering it practice for his midlife crisis",
      "because John's with the latest of conspiracy theory (involving squirrels)",
      "but John's still recovering from his last good idea",
      "and John is bringing his famous mystery casserole",
      "but John's still trying to figure out how to unmute himself",
      "because John's GPS always leads to adventure",
      "because John's dad jokes need a new audience",
      "but John's still waiting for his Windows 95 to update",
      "because John's convinced it's all a simulation anyway",
      "but John's still trying to figure out TikTok",
      "because John's horoscope said to expect the unexpected",
      "and John's bringing his extensive Dad Joke Encyclopedia",
    ],
  },

  {
    id: "funny",
    name: "Funny",
    extensions: [
      "but I'm bringing a cardboard cutout of myself just in case",
      "because my refrigerator recommended it",
      "and I'm bringing a kazoo for dramatic effect. Ohhh boyyyy!",
      "but only if I can bring my pet cactus",
      "and I'm channeling my inner Shakespeare",
      "because my alter ego thinks it's a good idea",
      "and I'm bringing my inflatable T-Rex costume",
      "and I'm writing a haiku about it as we speak",
      "and I'm bringing a bucket of glitter... just in case",
      "and I'm rehearsing my acceptance speech for 'Best Awkward Moment'",
      "because my spirit animal is a unicorn",
      "and I'm ready to redefine the meaning of 'awkward'",
    ],
  },

  {
    id: "introvert",
    name: "Introvert",
    extensions: [
      "and I'm already exhausted thinking about it",
      "and I'm freaking out",
      "and I have no idea what I'm doing",
      "and I'm questioning all my life choices",
      "and I'm surprisingly okay with it",
      "and I'm mentally preparing for human interaction",
      "but my emotional support water bottle says I can do this",
      "but first, let me consult my favorite comfort character",
      "and I'm practicing small talk in the mirror",
      "but I've got my exit strategy planned",
      "and I'm bringing a book, just in case",
      "but I'm limiting myself to exactly 2 hours of socializing",
    ],
  },
  {
    id: "extrovert",
    name: "Extrovert",
    extensions: [
      "and I can't wait!",
      "and I'm already planning the after-party",
      "and I'm hoping it's as fun as the picture in my head",
      "and I've invited everyone I know (and some I don't)",
      "and I'm ready to be the life of the party",
      "but I might need to clone myself to attend all the events",
      "and I'm bringing enough energy to power a small city",
      "and I've got icebreakers for days",
      "but I'm worried there won't be enough people there",
      "and I'm planning to turn it into a flash mob",
      "and I've already made 10 new best friends in line",
      "but I might need to schedule some introvert time to recover",
    ],
  },
  {
    id: "igotthis",
    name: "I Got This",
    extensions: [
      "and I am going to crush it",
      "and I'm aiming for greatness",
      "and I'm ready to make it happen",
      "and I'm channeling my inner superhero",
      "because I believe in myself",
      "and I'm ready to conquer the world",
      "because I'm stronger than ever",
      "and I'm determined to succeed",
      "because I'm unstoppable",
      "and nothing can stop me",
      "because today is my day",
      "and I'm about to show the world what I'm made of",
    ],
  },

  {
    id: "crazy",
    name: "Crazy",
    extensions: [
      "because I've decided to become a professional squirrel whisperer",
      "and I'm rehearsing my acceptance speech for 'Best Interpretive Dancer in the Milky Way'",
      "because the voices in my head had a vote and it was unanimous",
      "and I've decided to communicate exclusively through interpretive dance",
      "but only if Mercury is in retrograde and Jupiter aligns with Mars",
      "and I'm wearing my tin foil hat for protection",
      "because I'm training to be a professional unicorn wrangler",
      "and I've decided to start a new life as a sentient houseplant",
      "but first, I need to consult my imaginary board of directors",
      "and I'm channeling the spirit of my past life as a medieval jester",
      "because I'm preparing for the inevitable zombie apocalypse",
      "and I've decided to rename myself 'Captain Chaos' for the occasion",
    ],
  },
  {
    id: "cat",
    name: "Cat",
    extensions: [
      "because my cat approved",
      "because my cat's astrological chart said it's time",
      "but only after my cat finishes judging me silently",
      "and my cat's already claimed the box it came in",
      "but my cat's busy plotting world domination",
      "and my cat's giving me the 'I'm not impressed' look",
      "because my cat knocked everything off the counter to make room",
      "but my cat's too busy licking its butt to notice",
      "and my cat's already prepared a PowerPoint presentation on why it's a bad idea",
      "because my cat needs new material for its Instagram",
      "but my cat's busy calculating the exact center of the bed to sleep on",
      "and my cat's wondering why I'm not serving it right meow",
    ],
  },
  {
    id: "dog",
    name: "Dog",
    extensions: [
      "but only because my dog gave me puppy eyes",
      "and my dog thinks it's a new type of treat",
      "but my dog's too busy chasing its tail to care",
      "and my dog's excited to sniff some new butts",
      "because my dog heard the word 'walk' and now I can't back out",
      "but my dog's wondering why I'm not throwing the ball",
      "and my dog's already dug a hole in the yard to bury it",
      "because my dog needs new material for its 'guilty face' collection",
      "but my dog's too busy barking at its own reflection",
      "and my dog thinks it's a new game of 'fetch the human'",
      "because my dog's decided it's the perfect time for zoomies",
      "and my dog's practicing its 'human, I need to pee' dance",
    ],
  },

  {
    id: "adulting",
    name: "Adulting (Barely)",
    extensions: [
      "but I'll probably still eat cereal for dinner",
      "and I'm surprisingly excited about buying a vacuum cleaner",
      "but I'm still not sure how taxes work",
      "and I'm pretending to understand what a mortgage is",
      "but I'll celebrate with a juice box because I'm a grown-up",
      "and I'm planning to frame my first 'adulting' certificate",
      "but I'm still asking my mom how to use the washing machine",
      "and I'm ready to start my collection of tupperware lids that don't match anything",
      "but I'll probably still need a nap afterwards",
      "and I'm preparing a speech for when I successfully cook something without setting off the smoke alarm",
      "but I'm still convinced that the 'check engine' light is just a suggestion",
      "and I'm ready to pretend I know what I'm doing in life",
    ],
  },
  {
    id: "epicfail",
    name: "Epic Fail",
    extensions: [
      "but knowing me, I'll probably trip over my own feet",
      "and I'm already practicing my 'I meant to do that' face",
      "but I'll likely end up in a viral fail video instead",
      "and I'm prepared to laugh at myself when it inevitably goes wrong",
      "but I'm expecting to accidentally insult someone in a foreign language",
      "and I'm ready to turn my embarrassment into a new dance move",
      "but I'll probably call my boss 'Mom' by accident",
      "and I'm prepared to pretend it's performance art when I mess up",
      "but I'll likely spend most of the time trying to look like I belong",
      "and I'm ready to own my mistakes like they're limited edition collectibles",
      "but I'm anticipating at least one wardrobe malfunction",
      "and I'm prepared to claim my clumsiness is actually a superpower",
    ],
  },
  {
    id: "drunk",
    name: "Tipsy",
    extensions: [
      "but I'll probably drunk-text my ex and regret it immediately",
      "and I'm ready to declare my undying love to a slice of pizza",
      "but I might wake up wearing a traffic cone as a hat",
      "and I'm prepared to challenge strangers to a dance-off at 2 AM",
      "but I'll likely attempt karaoke and believe I'm the next Beyoncé",
      "and I'm anticipating a philosophical debate with a lamppost",
      "but I might accidentally order $50 worth of chicken nuggets",
      "and I'm ready to befriend everyone in the kebab shop",
      "but I'll probably try to recreate 'Risky Business' in my socks",
      "and I'm prepared to wake up and piece together the night like a detective",
      "but I might end up serenading a stray cat with '90s pop songs",
      "and I'm ready to explain to future me why buying that inflatable T-Rex costume was necessary",
    ],
  },
  {
    id: "procrastination",
    name: "Procrastination",
    extensions: [
      "but first, let me check social media one more time",
      "and I'm almost ready to start thinking about maybe beginning to prepare",
      "but I need to reorganize my sock drawer first",
      "and I'm just waiting for the perfect moment (which is never)",
      "but I need to watch one more YouTube video for 'research'",
      "and I'm nearly done perfecting my 'productive procrastination' technique",
      "but I suddenly need to alphabetize my spice rack",
      "and I'm just waiting for my zodiac sign to align with Mars",
      "but I need to find the perfect playlist to get in the mood",
      "and I'm almost finished creating a color-coded schedule I'll never follow",
      "but I need to master the art of looking busy first",
      "and I'm just waiting for my future self to handle it",
    ],
  },
  {
    id: "firstworldproblems",
    name: "First World Problems",
    extensions: [
      "but the Wi-Fi might be slightly slower than usual",
      "and I'm worried my latte art might not be Instagram-worthy",
      "but I can't decide which filter makes me look more carefree",
      "and I'm concerned my avocado toast isn't aesthetically pleasing enough",
      "but I might have to park slightly further away and actually walk",
      "and I'm stressed about choosing between 200 TV shows to binge",
      "but my phone might die before I can ignore all these messages",
      "and I'm anxious about whether my outfit is too basic or not basic enough",
      "but I'm worried about running out of storage for my cloud-synced photos of clouds",
      "and I'm conflicted about which overpriced organic juice to cleanse with",
      "but I'm concerned my smart fridge might be judging my eating habits",
      "and I'm stressed about finding the perfect caption for my #blessed post",
    ],
  },
  {
    id: "conspiracy",
    name: "Conspiracy",
    extensions: [
      "but the lizard people might be watching",
      "and the Illuminati have given their approval",
      "but only if the chemtrails align perfectly",
      "and my tinfoil hat is at maximum power",
      "but the flat Earth society needs to verify first",
      "and the moon landing studio is booked for rehearsals",
      "but the birds aren't real and they're all listening",
      "and bigfoot is my Uber driver",
      "but aliens are using my Wi-Fi to phone home",
      "and the Bermuda Triangle has triangulated my location",
      "but only if I can decode the hidden messages in my alphabet soup",
      "and the squirrels are secretly controlling the stock market",
    ],
  },
];

// Flatten all preset events for easier access
export const presetEvents = categories
  .find((category) => category.id === "holidays")
  .options.map((option) => ({
    ...option,
    categoryId: "holidays",
  }));
