import React, { useEffect, useContext } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { ThemeContext } from "../components/ThemeContext"; // Import ThemeContext

const PrivacyPolicy = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext); // Get isDarkMode from ThemeContext

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`flex flex-col min-h-screen ${isDarkMode ? "dark" : ""}`}>
      <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode}/>
      <div className="flex-grow bg-gray-100 dark:bg-gray-900 flex flex-col">
        <div className="container mx-auto px-4 py-8 flex-grow">
          <h1 className="text-3xl font-bold text-blue-900 dark:text-blue-300 mb-4">
            Privacy Policy
          </h1>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            At DaysToGoGo, we are committed to protecting your privacy. This
            Privacy Policy explains how we handle information on our website.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            1. Information Collection
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            Our website is static and does not require any login. We do not
            collect any personal information from our users.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            2. Cookies and Local Storage
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We use local storage to remember your preferred timezone and theme
            settings. This information is stored on your device and is not
            transmitted to our servers.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            3. Third-Party Services
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We may use third-party services, such as hosting providers or
            content delivery networks. These services may have access to your IP
            address and basic usage data.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            4. Advertisements and Tracking
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            Our website may display advertisements from third-party services, such as Google Ads. These third-party advertisers may use cookies, web beacons, or other tracking technologies to collect information about your activity on our website. This information is used to provide personalized advertisements based on your browsing behavior. You can manage your cookie preferences through your browser settings or opt-out of interest-based advertising through services like the Network Advertising Initiative (NAI) or the Digital Advertising Alliance (DAA).
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            5. Changes to This Policy
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <h2 className="text-2xl font-semibold mt-6 mb-2 text-gray-800 dark:text-gray-200">
            6. Contact Us
          </h2>
          <p className="mb-4 text-gray-700 dark:text-gray-300">
            If you have any questions about this Privacy Policy, please contact
            us at daystogogo.com@domainsbyproxy.com.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
