import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { categories, imageOptions, extensionCategories } from '../data/CategoryData';
import CountdownTimer from '../components/CountdownTimer';
import Navbar from '../components/Navbar';
import { useTimeZone } from '../App';
import { ThemeContext } from '../components/ThemeContext';
import { parseISO, isBefore, isEqual, addYears, format } from 'date-fns';
import { updateSpecialDates } from '../components/DateCalculations';
import Footer from '../components/Footer';



const EventPage = () => {
  const { id, categoryIndex, optionIndex, extensionCategoryIndex, extensionIndex, date, imageIndex } = useParams();
  const [eventDetails, setEventDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isImageFront, setIsImageFront] = useState(false);
  const { timeZone } = useTimeZone();
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleImageFront = () => {
    setIsImageFront(!isImageFront);
  };

  useEffect(() => {
    const fetchEventDetails = () => {
      try {
        let details;
        if (id) {
          // Handle preset event
          const presetEvent = categories.flatMap(category => 
            category.options.filter(option => option.id === id)
          )[0];

          if (!presetEvent) {
            throw new Error(`Preset event not found for id: ${id}`);
          }

          // Apply special date calculation for specific events
          const updatedEvents = updateSpecialDates([presetEvent], timeZone);
          const updatedEvent = updatedEvents[0];

          let eventDate = parseISO(updatedEvent.date);
          const today = new Date();

          // Check if the event date is in the past and forward the year if necessary
          if (isBefore(eventDate, today) && !isEqual(eventDate, today)) {
            eventDate = addYears(eventDate, 1);
          }

          details = {
            title: updatedEvent.name,
            date: format(eventDate, 'yyyy-MM-dd\'T\'HH:mm'),
            formattedDate: format(eventDate, "EEE, do MMM yyyy 'at' h:mm a"),
            backgroundImage: updatedEvent.image || categories.find(c => c.options.includes(presetEvent)).defaultImage,
            description: updatedEvent.description || "Preset event"
          };
        } else if (categoryIndex && optionIndex) {
          // Handle custom event
          const category = categories[parseInt(categoryIndex)];
          if (!category) throw new Error(`Category not found for index: ${categoryIndex}`);

          const option = category.options[parseInt(optionIndex)];
          if (!option) throw new Error(`Option not found for index: ${optionIndex}`);

          let extension = '';
          if (extensionCategoryIndex && extensionIndex) {
            const extensionCategory = extensionCategories[parseInt(extensionCategoryIndex)];
            if (extensionCategory) {
              extension = extensionCategory.extensions[parseInt(extensionIndex)] || '';
            }
          }

          const backgroundImage = imageIndex ? imageOptions[parseInt(imageIndex)] : category.defaultImage;
          const eventDate = parseISO(date);
          
          details = {
            title: `Time until I ${option.name}${extension ? ` ${extension}` : ''}`,
            date: date,
            formattedDate: format(eventDate, "EEE, do MMM yyyy 'at' h:mm a"),
            backgroundImage,
            description: option.description || ""
          };
        } else {
          throw new Error("Invalid event parameters");
        }
        setEventDetails(details);
      } catch (err) {
        console.error("Error fetching event details:", err);
        setError(err.message);
      }
    };

    fetchEventDetails();
  }, [id, categoryIndex, optionIndex, extensionCategoryIndex, extensionIndex, date, imageIndex, timeZone]);

  const shareUrl = window.location.href;
  const shareText = `Check out this countdown: ${eventDetails?.title}`;

  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
  };

  const shareOnTwitter = () => {
    window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}&url=${encodeURIComponent(shareUrl)}`, '_blank');
  };

  const shareOnInstagram = () => {
    window.open('https://www.instagram.com', '_blank');
  };

  if (error) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
          <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
          <div className="flex items-center justify-center h-full">
            <p className="text-red-500">Error: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (!eventDetails) {
    return (
      <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
        <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
          <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
          <div className="flex items-center justify-center h-full">
            <p>Loading...</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? 'dark' : ''}`}>
      <div className="relative min-h-screen flex flex-col">
        {!isExpanded && (
          <div className="fixed inset-x-0 top-0 z-50">
            <Navbar toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
          </div>
        )}

        <div className={`flex-grow flex items-center justify-center bg-gray-100 dark:bg-gray-900 ${isExpanded ? 'fixed inset-0 z-50' : 'relative z-0 pt-20'}`}>
          <div className={`${isExpanded ? 'w-full h-full' : 'w-full max-w-4xl'} relative`}>
            <motion.div 
              initial={{ opacity: 0 }} 
              animate={{ opacity: 1 }} 
              exit={{ opacity: 0 }}
              className="w-full h-full bg-gradient-to-br from-blue-700 to-blue-900 dark:from-gray-800 dark:to-black rounded-lg shadow-xl overflow-hidden relative"
              style={{
                backgroundImage: `url(${eventDetails.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
              }}
            >
              <div className="absolute inset-0 bg-black opacity-30"></div>

              <div className={`absolute ${isExpanded ? 'top-4 right-4' : 'top-2 right-2'} z-20 flex space-x-2`}>
                <button
                  onClick={toggleExpand}
                  className="bg-white dark:bg-gray-800 text-gray-800 dark:text-white p-2 rounded-full shadow-md hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200"
                >
                  {isExpanded ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
                    </svg>
                  )}
                </button>
              </div>
              <div className={`relative z-10 p-8 h-full flex flex-col justify-center`}>
          <h1 className={`font-bold text-white mb-4 break-words ${
            isExpanded 
              ? 'text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl' 
              : 'text-xl sm:text-2xl md:text-3xl lg:text-4xl'
          }`}>
            {eventDetails.title}
          </h1>
          <p className={`text-white mb-6 ${isExpanded ? 'text-lg sm:text-xl md:text-2xl' : 'text-base sm:text-lg md:text-xl'}`}>
            {eventDetails.formattedDate}
          </p>
          <CountdownTimer targetDate={eventDetails.date} timeZone={timeZone} isExpanded={isExpanded} />
                {eventDetails.description && (
                  <p className={`text-white mt-6 ${isExpanded ? 'text-base sm:text-lg md:text-xl' : 'text-sm sm:text-base md:text-lg'}`}>
                    {eventDetails.description}
                  </p>
                )}
                <div className="flex space-x-4 mt-6">
                  <button onClick={shareOnFacebook} className="text-white hover:text-blue-400">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/></svg>
                  </button>
                  <button onClick={shareOnTwitter} className="text-white hover:text-blue-400">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z"/></svg>
                  </button>
                  <button onClick={shareOnInstagram} className="text-white hover:text-blue-400">
                    <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                  </button>
                </div>
              </div>
            </motion.div>
          </div>
        </div>

        {!isExpanded && (
          <Footer></Footer>
        )}
      </div>
    </div>
  );
};

export default EventPage;